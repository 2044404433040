<template>
  <div>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-data-table
        :key="panelKey"
        :headers="headersApuracao"
        :items="contrato.apuracoes"
        item-key="idApuracao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :expanded.sync="expanded"
        show-expand
        class="elevation-0"
        :no-data-text="$t('label.tabela_sem_conteudo')">
        <template v-slot:[`item.checkbox`]="{ item }">
          <v-checkbox
            v-model="item.selecionado"
            v-if="!statusAprovado(item)"
            hide-details
            class="shrink pt-0 mt-0"
            @click.stop="(e) => selecionarItem(item, e)">
          </v-checkbox>
        </template>
        <template v-slot:[`item.icon`]="{ item }">
          <v-tooltip bottom v-if="!statusAprovado(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="orange darken-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                report_problem
              </v-icon>
            </template>
            <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="statusAprovado(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="deep-orange accent-4"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>{{ $t('label.periodo_apurado') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <v-menu bottom
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :id="'more_vert_' + item.id"
                icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="detalharApuracao(item)">
                <v-list-item-action>
                  <v-icon>details</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!statusAprovado(item)"
                @click="cancelamentoApuracao(item)">
                <v-list-item-action>
                  <v-icon>cancel</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!statusAprovado(item)"
                @click="abrirModalRecebimentoParcial(item)">
                <v-list-item-action>
                  <v-icon>paid</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.recebimento_especial', 1) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.periodoVigencia`]="{ item }">
          {{ `${item.dataInicio}` }}<br /> {{ `${item.dataFim}` }}
        </template>

        <template v-slot:[`item.verba`]="{ item }">
          <span v-if="item.indMetaIntervalo">
            <v-btn
              icon
              @click="abrirModalIntervaloMeta(item)"
              class="mx-2"
            >
              <v-icon>
                add_box
              </v-icon>
            </v-btn>
          </span>
          <span v-else>
            {{ formatarVerba(item) }}
          </span>
        </template>

        <template v-slot:[`item.vlrRealizado`]="{ item }">
          <v-container fluid>
            <v-row>
              <v-col cols="12" v-if="indEdicaoRealizado(item)" :sm="indRealizadoDetalhamento(item) ? 8 : 12">
                <v-text-field
                  v-if="realizadoPorQuantidade(item)"
                  :id="`vlrRealizado-${item.idApuracao}`"
                  :ref="`vlrRealizado-${item.idApuracao}`"
                  v-model="item.vlrRealizado"
                  :rules="[rules.required, rules.valorAjusteNegativo]"
                  :disabled="statusAprovado(item)"
                  type="Number"
                  style="min-width:80px"
                  :hint="String(item.vlrRealizadoOriginal)"
                  @focus="guardarValorInicial(item)"
                  @change="alteracaoValorRealizado(item)"
                ></v-text-field>
                <v-text-field
                  v-else-if="item.calculoApuracao === 'SELLIN_VALOR'"
                  :id="`vlrRealizado-${item.idApuracao}`"
                  :ref="`vlrRealizado-${item.idApuracao}`"
                  v-model="item.vlrRealizado"
                  :rules="[rules.required]"
                  :disabled="statusAprovado(item)"
                  type="Number"
                  style="min-width:140px"
                  prefix="R$"
                  :hint="String(item.vlrRealizadoOriginal)"
                  @focus="guardarValorInicial(item)"
                  @change="alteracaoValorRealizado(item)"
                ></v-text-field>
                <v-text-field
                  :id="`vlrRealizado-${item.idApuracao}`"
                  :ref="`vlrRealizado-${item.idApuracao}`"
                  v-else
                  v-model="item.vlrRealizado"
                  :rules="[rules.required]"
                  :disabled="statusAprovado(item)"
                  type="Number"
                  style="min-width:80px"
                  suffix="%"
                  :hint="String(item.vlrRealizadoOriginal)"
                  @focus="guardarValorInicial(item)"
                  @change="alteracaoValorRealizado(item)"
                ></v-text-field>
                <span v-if="item.vlrRealizado !== item.vlrRealizadoOriginal" class="grey--text caption">
                  <br/>
                  {{ formatarValorRealizado(item.vlrRealizadoOriginal, item.calculoApuracao) }}
                </span>
              </v-col>
              <v-col class="mt-2" v-else cols="12" :sm="indRealizadoDetalhamento(item) ? 8 : 12">
                <span>
                  {{ formatarValorRealizado(item.vlrRealizado, item.calculoApuracao) }}
                </span>
                <span v-if="item.vlrRealizado !== item.vlrRealizadoOriginal" class="grey--text caption">
                  <br/>
                  {{ formatarValorRealizado(item.vlrRealizadoOriginal, item.calculoApuracao) }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" v-if="indRealizadoDetalhamento(item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="abrirModalRealizado(item.idApuracao)"
                      :class="indEdicaoRealizado(item) ? 'mx-2 mt-4' : ''"
                    >
                      <v-icon>
                        info
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.detalhamento_realizado', 1) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:[`item.valorBase`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span>
                {{ getMoney(item.valorBase) }}
                <span v-on="on" class="mb-1" v-if="item.valorBase !== item.valorBaseOriginal">
                  <popover-generico
                    icone="info"
                    label="label.valor_base_original"
                    :valor="item.valorBaseOriginal"
                    :is-valor-monetario="true"/>
                </span>
              </span>
            </template>
            <span>{{ $tc('label.valor_base_original', 1) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.recebimentoAtual`]="{ item }">
          {{ getMoney(item.recebimentoAtual) }}
        </template>

        <template v-slot:[`item.vlrRetroativo`]="{ item }">
          {{ getMoney(item.vlrRetroativo) }}
          <tooltip-ajuda v-if="item.vlrRetroativo" color="black"
            :mensagem="`${$t('message.valor_retroatividade', { descricaoContratoAnterior: item.descricaoContratoAnterior})}`"
          />
        </template>

        <template v-slot:[`item.recebimentoDefinido`]="{ item }">
          <tr>
            <td v-if="item.indEdicaoRecebimento && !statusAprovado(item)">
              <input-money
                v-model="item.recebimentoDefinido"
                :id="`valor_recebimento_${item.idApuracao}`"
                :rules="[rules.required, rules.valorPositivo, v => rules.valorMaiorQueEfetuado(v, item.valorRecebido)]"
                class="v-text-field"
                :hint="item.indValorDiferenteDoOriginal? $t('label.valor_original') + getMoney(item.recebimentoDefinidoOriginal) : ''"
                :persistent-hint="true"
                :css-style="'min-width:140px'"
                @blur="abrirModalJustificativaAlteracaoValor(item)"
                @change="validarValorAlterado(item)"
              ></input-money>
            </td>
            <td v-else>
              {{ getMoney(item.recebimentoDefinido) }}
            </td>
            <td v-if="indJustificativaAlterarValor(item)">
              <v-btn
                icon
                @click="abrirModalJustificativaAlteracaoValor(item, true)"
                class="mx-2"
              >
                <v-icon>
                description
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      <template v-slot:[`item.recebimentoPendente`]="{ item }">
        {{ getMoney(item.recebimentoPendente) }}
      </template>

      <template v-slot:[`item.tipoUsoNovo`]="{ item }">
        <v-autocomplete
          id="tipo-uso"
          class="custom-autocomplete"
          v-model="item.idTipoUsoNovo"
          :items="listaTiposUso"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          item-text="nome"
          item-value="id"
          :clearable="true"
          v-if="!statusAprovado(item)"
          @change="valorTipoUsoAlterado(item)"
        ></v-autocomplete>
        <span v-else>
          {{ item.tipoUsoNovo || item.tipoUso }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <apuracao-contrato-finalizar-lote-tabela-fornecedor
              :fornecedores="item.detalhes"
              :tipoVerba="item.formaBonificacao"
              :valorVerba="item.verba"
              :template="item.template"
              :ind-fechamento-parcial="item.indFechamentoParcial"
              :header-detalhes="headersDetalhes"
              :selecionar-item="selecionarItem"
              @ApuracaoContratoFinalizarLoteTabela__ModoEdicaoValoresFornecedor="atualizaModoEdicaoVlrFornecedor"
              @ApuracaoContratoFinalizarLoteTabela__RecarregarApuracoes="recarregarApuracoes">
          </apuracao-contrato-finalizar-lote-tabela-fornecedor>
        </td>
      </template>
      </v-data-table>
    </v-form>

    <!-- modal da justificativa alteração unitária de tipo de uso -->
    <v-dialog v-model="dialogJustificativaTipoUso" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('label.informe_justificativa') }}</span>
        </v-card-title>
        <v-form lazy-validation ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="justificativa_textarea"
                  required
                  :rows="1"
                  auto-grow
                  autofocus
                  maxlength="150"
                  v-model.trim="apuracaoSelecionada.justificativa"
                  :rules="[rules.required]">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="secundary"
            @click="modalJustificativaCancelar">{{ $t('label.cancelar') }}</v-btn>
          <v-btn text
            color="primary"
            :disabled="naoPermiteCancelar"
            @click="modalJustificativaConfirmar">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-justificativa
      ref="dialogJustificativa"
      :justificativa="apuracaoSelecionada.justificativaAlterarRecebimento"
      @confirmar="confirmarAlteracaoValor"
      @cancelar="cancelarAlteracaoValor">
    </dialog-justificativa>

    <dialog-apuracao-contrato-meta-intervalo
      ref="dialogMetaIntervalo">
    </dialog-apuracao-contrato-meta-intervalo>

    <dialog-realizado-detalhamento
      ref="dialogRealizadoDetalhamento">
    </dialog-realizado-detalhamento>
    <v-dialog
      v-model="exibeModalRecebimentoParcial"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <apuracao-contrato-modal-recebimento-parcial
        ref="modalRecebimentoParcial"
        :apuracao="apuracaoParcial"
        :confirmar-alteracao-valor="confirmarAlteracaoValor"
        @FecharModalRecebimentoParcial="fecharModalRecebimentoParcial">
      </apuracao-contrato-modal-recebimento-parcial>
    </v-dialog>
    <confirm
      ref="recebimentoParcialAtencao"
      :title="$t('title.atencao')"
      :maxWidth="'500px'"
      :hideDisagree="true"
      :agreeLabel="$t('label.ok')"
      :message="$t('message.recebimento_parcial_atencao')"
    >
    </confirm>
  </div>
</template>

<script>
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/produto/common/functions/autocomplete-utils';
import { buscarDetalhesApuracaoContrato } from '../../common/resources/apuracao/apuracao-contrato';
import { cancelarApuracao } from '../../common/resources/apuracao/apuracao-acao-edicao';
import { getMoney, getPercent, getNumber } from '../../common/functions/helpers';
import { numberBetweenValues } from '../../common/functions/numeric';
import PopoverLista from '../../shared-components/PopoverLista';
import InputMoney from '../../shared-components/inputs/InputMoney';
import DialogJustificativa from '../../shared-components/vuetify/dialog/DialogJustificativa';
import DialogApuracaoContratoMetaIntervalo from './modals/DialogApuracaoContratoMetaIntervalo';
import DialogRealizadoDetalhamento from './modals/DialogRealizadoDetalhamento';
import ApuracaoContratoModalRecebimentoParcial from './modals/ApuracaoContratoModalRecebimentoParcial';
import ApuracaoContratoFinalizarLoteTabelaFornecedor from './ApuracaoContratoFinalizarLoteTabelaFornecedor';
import ApuracaoContratoFinalizarLoteTabelaData from './ApuracaoContratoFinalizarLoteTabelaData';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import PopoverGenerico from '../../shared-components/PopoverGenerico';
import TooltipAjuda from '../../shared-components/TooltipAjuda';

export default {
  name: 'ApuracaoContratoFinalizarLoteTabela',
  mixins: [
    ApuracaoContratoFinalizarLoteTabelaData,
  ],
  components: {
    PopoverLista,
    InputMoney,
    DialogJustificativa,
    DialogApuracaoContratoMetaIntervalo,
    DialogRealizadoDetalhamento,
    ApuracaoContratoModalRecebimentoParcial,
    Confirm,
    PopoverGenerico,
    TooltipAjuda,
    ApuracaoContratoFinalizarLoteTabelaFornecedor,
  },
  props: {
    contrato: {
      type: Object,
    },
    filtros: {
      type: Object,
      default: () => ({}),
    },
    listaTiposUso: {
      type: Array,
      default: () => ([]),
    },
    selecionarItem: Function,
    atualizarRecebimentoDefinido: Function,
    aplicarFiltros: Function,
    recarregarApuracoesLote: Function,
  },
  data() {
    return {};
  },
  computed: {
    naoPermiteCancelar() {
      return this.apuracaoSelecionada.justificativa === '' || this.apuracaoSelecionada.justificativa === undefined;
    },
  },
  watch: {
    expanded(apuracoes) {
      this.buscarApuracoesNaoBuscadas(apuracoes);
    },
  },
  methods: {
    getPercent,
    getMoney,
    getNumber,
    buscaAutocomplete,
    triggerSelecao,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    formatarValorRealizado(valor, calculoApuracao) {
      if (valor === 0) {
        return '-';
      }
      if (calculoApuracao === 'SELLIN_VALOR') {
        return this.getMoney(valor);
      }
      if (calculoApuracao === 'SELLIN_PERCENTUAL') {
        return this.getPercent(valor);
      }
      return this.getNumber(valor);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    salvarTipoUsoIndividual() {
      const { idApuracao } = this.apuracaoSelecionada;
      this.workflowApuracaoAcao.salvarApuracaoAcaoContratoTipoUso({ idApuracao },
        {
          tipo_uso: this.apuracaoSelecionada.idTipoUsoNovo,
          justificativa_tipo_uso: this.apuracaoSelecionada.justificativa,
        })
        .then(() => {
          this.closeModalJustificativa();
          this.$toast(this.$t('message.tipo_uso_alterado_sucesso'));
          this.$emit('tipoUsoAlterado', this.contrato.idContrato, this.apuracaoSelecionada);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    valorTipoUsoAlterado(item) {
      if (item.idTipoUsoNovo) {
        this.modalJustificativaFlag = 'TIPO_USO';
        this.openModalJustificativa(item);
      } else {
        item.justificativa = '';
      }
    },
    modalJustificativaCancelar() {
      this.apuracaoSelecionada.tipoUsoNovo = '';
      this.apuracaoSelecionada.justificativa = '';
      this.closeModalJustificativa();
    },
    salvarAlteracaoTipoUso() {
      if (!this.$refs.form.validate()) return;
      this.salvarTipoUsoIndividual();
    },
    openModalJustificativa(item) {
      this.apuracaoSelecionada = item;
      this.dialogJustificativaTipoUso = true;
    },
    closeModalJustificativa() {
      this.dialogJustificativaTipoUso = false;
    },
    modalJustificativaConfirmar() {
      switch (this.modalJustificativaFlag) {
        case 'TIPO_USO':
          this.salvarAlteracaoTipoUso();
          break;
        case 'CANCELAR_APURACAO':
          this.confirmarCancelarApuracao();
          break;
        default:
          console.log(this.modalJustificativaFlag);
      }
    },
    cancelamentoApuracao(item) {
      if (item.valorRecebido > 0) {
        this.$toast(this.$t('message.apuracao_possui_recebimento_parcial'));
        return;
      }
      this.modalJustificativaFlag = 'CANCELAR_APURACAO';
      this.openModalJustificativa(item);
    },
    confirmarCancelarApuracao() {
      const apuracao = this.apuracaoSelecionada;
      const { idApuracao } = apuracao;
      const param = {
        idApuracao,
        justificativa: this.apuracaoSelecionada.justificativa,
      };
      cancelarApuracao(param, this.$resource)
        .then(() => {
          const index = this.contrato.apuracoes.findIndex((a) => a.idApuracao === idApuracao);
          this.contrato.apuracoes.splice(index, 1);
          if (!this.contrato.apuracoes.length) {
            const params = {
              manterPagina: true,
            };
            this.aplicarFiltros(params);
          }
          this.closeModalJustificativa();
          this.$toast(this.$t('message.apuracao_cancelada_sucesso'));
          this.apuracaoSelecionada = {};
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    detalharApuracao(item) {
      const params = {
        ...this.filtros,
        id: item.idApuracao,
        idAcao: item.idAcao,
        idContrato: item.idContrato,
        status: item.statusApuracao,
      };

      const rota = 'detalharApuracaoContrato';

      const query = {
        origemContrato: item.origemContrato,
      };

      this.$router.push({ name: rota, params, query });
    },
    buscarDetalhesApuracao(idsApuracao, statusApuracao = null) {
      return buscarDetalhesApuracaoContrato({ cod_apuracao: idsApuracao.split(',') }, this.$resource)
        .then((response) => {
          const apuracao = this.contrato.apuracoes.find((a) => a.idApuracao === idsApuracao);
          this.atualizarHeadersDetalhes(apuracao);
          apuracao.detalhes = response.data;
          this.$emit('adicionarFornecedoresSelecionados', apuracao.idApuracao, response.data);
          apuracao.detalhes.forEach((e) => {
            e.statusApuracao = statusApuracao;
            e.selecionado = !!apuracao.selecionado;
          });
          //  Calculo para adicionar o resto ao maior valor de ND.
          if (apuracao.detalhes.length) {
            const detalheMaiorValor = apuracao.detalhes.reduce((detalheAnterior, detalhe) => {
              if (detalheAnterior.valorNdPrevisto > detalhe.valorNdPrevisto) {
                return detalheAnterior;
              }
              return detalhe;
            });
            const somaValorNdPrevisto = apuracao.detalhes.reduce((a, { valorNdPrevisto }) => a + valorNdPrevisto, 0);
            const diferenca = detalheMaiorValor.vlrConfApuracao - somaValorNdPrevisto;
            if (diferenca !== 0 && detalheMaiorValor.valorNdPrevisto !== 0) {
              detalheMaiorValor.valorNdPrevisto += diferenca;
              detalheMaiorValor.valorNdPrevistoOriginal += diferenca;
            }
          }
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirModalJustificativaAlteracaoValor(item, acaoBotao = false) {
      this.guardarValorInicial(item);
      this.apuracaoSelecionada = item;
      this.$emit('desabilitaBotao', true);
      const apuracao = this.contrato.apuracoes.find((a) => a.idApuracao === item.idApuracao);
      if ((item.recebimentoDefinidoOriginal !== item.recebimentoDefinido)
        || acaoBotao) {
        apuracao.indJustificativa = true;
        if (this.statusAprovado(item)) {
          this.$refs.dialogJustificativa.openReadOnly(item.justificativaAlterarRecebimento);
        } else {
          this.$refs.dialogJustificativa.open();
        }
      } else {
        this.$emit('desabilitaBotao', false);
      }
    },
    validarValorAlterado(item) {
      if (item.recebimentoDefinidoOriginal !== item.recebimentoDefinido) {
        item.indValorDiferenteDoOriginal = true;
      } else {
        item.indValorDiferenteDoOriginal = false;
      }
      this.reRenderizarTable();
    },
    guardarValorInicial(item) {
      item.valorRealizadoInicial = item.vlrRealizado;
      item.recebimentoDefinidoInicial = item.recebimentoDefinido;
    },
    alteracaoValorRealizado(item) {
      if (item.vlrRealizado === '' || item.vlrRealizado < 0
        || (item.vlrRealizado === item.valorRealizadoInicial)) {
        return;
      }
      this.apuracaoSelecionada = item;
      const params = {};
      params.valorRealizado = this.apuracaoSelecionada.vlrRealizado;
      if (this.apuracaoSelecionada.indPreenchimentoValorRealizado && this.apuracaoSelecionada.indAgrupamentoCategoriaKpi) {
        params.vlrConfApuracao = this.apuracaoSelecionada.vlrRealizado * this.apuracaoSelecionada.verba;
        if (params.vlrConfApuracao !== item.recebimentoDefinidoInicial) {
          this.apuracaoSelecionada.recebimentoDefinido = params.vlrConfApuracao;
          this.$refs.dialogJustificativa.open();
        } else {
          this.atualizarValor(params);
        }
      } else if (this.apuracaoSelecionada.indEdicaoRealizado) {
        this.buscarValorBonificacaoMeta(this.apuracaoSelecionada.vlrRealizado).then((valorBonificacao) => {
          if (this.apuracaoSelecionada.template === 'META_POR_QUANTIDADE') {
            if (this.apuracaoSelecionada.formaBonificacao === 'PERCENTUAL') {
              params.vlrConfApuracao = ((this.apuracaoSelecionada.vlrRealizado / 100) * valorBonificacao).toFixed(2);
            } else {
              params.vlrConfApuracao = valorBonificacao;
            }
          } else if (this.apuracaoSelecionada.formaBonificacao === 'PERCENTUAL') {
            params.vlrConfApuracao = ((this.apuracaoSelecionada.valorBase / 100) * valorBonificacao).toFixed(2);
          } else {
            params.vlrConfApuracao = valorBonificacao;
          }
          if (params.vlrConfApuracao !== item.recebimentoDefinidoInicial) {
            this.apuracaoSelecionada.recebimentoDefinido = params.vlrConfApuracao;
            this.$refs.dialogJustificativa.open();
          } else {
            this.atualizarValor(params);
          }
        });
      }
    },
    indRealizadoDetalhamento(item) {
      return item.calculoApuracao === 'SELLIN_PERCENTUAL' && item.template === 'CRESCIMENTO';
    },
    indJustificativaAlterarValor(item) {
      return (item.indJustificativaAlterarValor && item.indJustificativa)
        || item.justificativaAlterarRecebimento;
    },
    realizadoPorQuantidade(item) {
      return (item.indPreenchimentoValorRealizado && item.template === 'META_N/A')
        || item.template === 'META_POR_QUANTIDADE';
    },
    confirmarAlteracaoValor(justificativa, apuracao = null) {
      this.$emit('desabilitaBotao', false);
      if (apuracao) {
        this.apuracaoSelecionada = apuracao;
        this.apuracaoParcial = apuracao;
      }
      const params = {
        justificativaAlteracaoValorPagamento: justificativa,
        vlrConfApuracao: parseFloat(this.apuracaoSelecionada.recebimentoDefinido),
        valorRealizado: this.apuracaoSelecionada.vlrRealizado,
      };

      this.atualizarValor(params);
    },
    cancelarAlteracaoValor() {
      const nomeComponente = 'valor_recebimento_'.concat(this.apuracaoSelecionada.idApuracao);
      this.retornarValoresIniciaisApuracao();
      this.$emit('desabilitaBotao', false);
      setTimeout(() => {
        const componente = document.getElementById(nomeComponente);
        if (componente !== null && componente !== undefined) {
          componente.focus();
        }
      });
    },
    retornarValoresIniciaisApuracao() {
      const apuracao = this.contrato.apuracoes.find((a) => a.idApuracao === this.apuracaoSelecionada.idApuracao);
      apuracao.vlrRealizado = this.apuracaoSelecionada.valorRealizadoInicial;
      apuracao.recebimentoDefinido = this.apuracaoSelecionada.recebimentoDefinidoInicial;
      this.apuracaoSelecionada = {};
    },
    atualizarValor(params = {}) {
      const parametros = params;
      parametros.indApuracaoAgrupada = this.apuracaoSelecionada.indAgrupamentoCategoriaKpi;
      parametros.valorRealizadoOriginal = this.apuracaoSelecionada.recebimentoAtual;
      parametros.id = Number(this.apuracaoSelecionada.idApuracao.split(',')[0]);
      parametros.idContrato = this.contrato.idContrato;
      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          const apuracao = this.contrato.apuracoes.find((a) => a.idApuracao === this.apuracaoSelecionada.idApuracao);
          apuracao.valorRealizadoInicial = apuracao.vlrRealizado;
          const somaValor = this.contrato.apuracoes.filter((a) => a.idContrato === apuracao.idContrato)
            .map((a) => parseFloat(a.recebimentoDefinido))
            .reduce((a, b) => a + b || 0);
          this.atualizarRecebimentoDefinido(apuracao.idContrato, somaValor, apuracao.idApuracao).then(() => {
            this.buscarDetalhesApuracao(apuracao.idApuracao).then(() => {
              apuracao.recebimentoDefinidoOriginal = params.vlrConfApuracao;
              apuracao.recebimentoDefinido = params.vlrConfApuracao;
              apuracao.justificativaAlterarRecebimento = params.justificativaAlteracaoValorPagamento;
              if (apuracao.valorRecebido) {
                apuracao.recebimentoPendente = apuracao.recebimentoDefinido - apuracao.valorRecebido;
              }
              this.apuracaoSelecionada = apuracao;
              this.apuracaoParcial = apuracao;
              this.indJustificativa = false;
              this.apuracaoSelecionada = {};
              this.$toast(this.$t('message.atualizar_tabela'));
              this.reRenderizarTable();
            });
          });
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    buscarValorBonificacaoMeta(valor) {
      let valorBonificacao = 0;
      return this.resourceApuracaoAcaoListagem.buscarMetas({ idAcao: Number(this.apuracaoSelecionada.idAcao.split(',')[0]) }).then((res) => {
        this.metas.push(...res.data);
        if (Math.max(...this.metas.map((meta) => meta.vlrMetaAte)) < valor) {
          return Math.max(...this.metas.map((meta) => meta.vlrBonificacao));
        }
        const menorMeta = this.metas.find((meta) => meta.vlrMetaDe === 0);
        if (!!menorMeta && valor <= 0) {
          return menorMeta.vlrBonificacao;
        }
        this.metas.forEach((meta) => {
          if (numberBetweenValues(valor, meta.vlrMetaDe, meta.vlrMetaAte)) {
            valorBonificacao = meta.vlrBonificacao;
          }
        });
        return valorBonificacao;
      });
    },
    abrirModalIntervaloMeta(item) {
      const idAcao = Number(item.idAcao);
      const { calculoApuracao, formaBonificacao } = { ...item };
      this.$refs.dialogMetaIntervalo.open(idAcao, calculoApuracao, formaBonificacao);
    },
    indEdicaoRealizado(item) {
      return item.indEdicaoRealizado || item.indPreenchimentoValorRealizado;
    },
    abrirModalRealizado(idApuracao) {
      this.$refs.dialogRealizadoDetalhamento.open(idApuracao);
    },
    alterarCheckboxApuracoes(selecionado) {
      if (this.contrato.apuracoes && this.contrato.apuracoes.length) {
        this.contrato.apuracoes.forEach((a) => {
          a.selecionado = selecionado;
        });
      }
    },
    vlrRealizado(apuracao) {
      if (apuracao.vlrRealizado === 0) {
        apuracao.vlrRealizado = '';
        apuracao.recebimentoAtual = 0;
        apuracao.recebimentoDefinido = 0;
        apuracao.recebimentoDefinidoOriginal = 0;
      }
      return apuracao.vlrRealizado;
    },
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    reRenderizarTable() {
      this.panelKey += 1;
    },
    fecharModalRecebimentoParcial(apuracaoFinalizada = false) {
      const params = {
        manterPagina: true,
      };
      if (apuracaoFinalizada) {
        const { idApuracao } = this.apuracaoParcial;
        const index = this.contrato.apuracoes.findIndex((a) => a.idApuracao === idApuracao);
        this.contrato.apuracoes.splice(index, 1);
        if (!this.contrato.apuracoes.length) {
          this.aplicarFiltros(params);
        }
      } else {
        this.aplicarFiltros(params);
      }
      this.exibeModalRecebimentoParcial = false;
    },
    abrirModalRecebimentoParcial(apuracao) {
      this.apuracaoSelecionada = apuracao;
      // if (!this.habilitarRecebimentoParcial(apuracao)) {
      //   this.$refs.recebimentoParcialAtencao.open();
      //   return;
      // }
      this.apuracaoParcial = apuracao;
      this.exibeModalRecebimentoParcial = true;
    },
    habilitarRecebimentoParcial(apuracao) {
      return apuracao.indFechamentoParcial
        && apuracao.template === 'META_N/A'
        && !apuracao.indPreenchimentoValorRealizado
        && (apuracao.formaBonificacao === 'FIXO' || apuracao.formaBonificacao === 'PERCENTUAL')
        && !apuracao.indMetaIntervalo
        && !apuracao.indFornecedorFinalizado;
    },
    atualizarHeadersDetalhes() {
      if (!this.headersDetalhes.some((h) => h.value === 'valorNdGerado')) {
        this.headersDetalhes.push(
          {
            text: this.$tc('label.valor_nd_gerado', 2), value: 'valorNdGerado', cellClass: 'nowrap',
          },
        );
      }
    },
    abrirModalValorBase(idApuracao) {
      this.$refs.exibeModalValorBase.open(idApuracao);
    },
    atualizaModoEdicaoVlrFornecedor(isModoEdicao) {
      this.isModoEdicaoVlrFornecedor = isModoEdicao;
    },
    buscarApuracoesNaoBuscadas(apuracoes) {
      if (!apuracoes.length) {
        return;
      }
      const idsNaoBuscados = apuracoes.filter((e) => !this.idsApuracaoDetalhes.includes(e.idApuracao));
      if (idsNaoBuscados && idsNaoBuscados.length) {
        const { idApuracao, statusApuracao } = idsNaoBuscados[0];
        if (idApuracao) {
          this.idsApuracaoDetalhes.push(idApuracao);
          this.buscarDetalhesApuracao(idApuracao, statusApuracao);
        }
      }
    },
    recarregarApuracoes(idApuracao) {
      this.recarregarApuracoesLote(this.contrato.idContrato).then(() => {
        const somaValorRecebimentoDefinido = this.contrato.apuracoes
          .map((a) => parseFloat(a.recebimentoDefinido))
          .reduce((a, b) => a + b || 0);
        this.contrato.vlrRecebimento = somaValorRecebimentoDefinido;
        this.buscarDetalhesApuracao(idApuracao).then(() => {
          this.$emit('ApuracaoContratoFinalizarLote__reRenderizarPanel', this.contrato.idContrato, this.expanded);
        });
      });
    },
    setarApuracoesExpandidas(expanded) {
      this.expanded = [...expanded];
    },
  },
};
</script>
