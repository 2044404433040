export default {
  data() {
    return {
      workflowApuracaoAcao: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      resourceApuracaoAcaoListagem: this.$api.apuracaoAcaoListagem(this.$resource),
      panelKey: 0,
      totalPageApuracao: 0,
      esperar: false,
      apuracoes: [],
      recebimentoSemValorNaoEmitido: true,
      dialogJustificativaTipoUso: false,
      apuracaoSelecionada: {},
      modalJustificativaFlag: '',
      isModoEdicaoVlrFornecedor: false,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
        valorAjusteNegativo: (value) => value >= 0 || this.$t('errors.valor_negativo'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQueEfetuado: (value, valorRecebido) => (!!value && value >= valorRecebido)
          || this.$t('errors.valor.menor_que_efetuado'),
      },
      headersApuracao: [
        { text: '', value: 'checkbox', sortable: false },
        { text: '', value: 'icon', sortable: false },
        {
          text: this.$tc('label.acao', 2), value: 'acoes', class: 'nowrap',
        },
        {
          text: this.$tc('label.cod_acao', 1), value: 'idAcao', class: 'nowrap',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', class: 'nowrap',
        },
        { text: this.$tc('title.categorias', 1), value: 'categoria', class: 'nowrap' },
        { text: this.$tc('label.periodo_vigencia', 1), value: 'periodoVigencia' },
        {
          text: this.$tc('label.verba', 1), value: 'verba', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.realizado', 1), value: 'vlrRealizado', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base', 1), value: 'valorBase', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_atual', 1), width: '10%', value: 'recebimentoAtual', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.ajuste_contratual', 1), width: '10%', value: 'vlrRetroativo', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_definido', 1), value: 'recebimentoDefinido', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_pendente', 1), value: 'recebimentoPendente', cellClass: 'nowrap',
        },
        { text: this.$tc('label.tipo_uso_atual', 1), value: 'tipoUso' },
        { text: this.$tc('label.tipo_uso_definido', 1), value: 'tipoUsoNovo', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      headersDetalhes: [
        { text: '', value: 'checkbox', sortable: false },
        { text: '', value: 'exportar', sortable: false },
        { text: '', value: 'icon', sortable: false },
        {
          text: this.$tc('label.cod_fornecedor'), value: 'codFornecedor', class: 'nowrap', cellClass: 'align-right',
        },
        { text: this.$tc('label.razao_social'), value: 'razaoSocial' },
        {
          text: this.$tc('label.cnpj'), value: 'cnpj', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.categoria'), value: 'categoria', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_de_entrada'), value: 'total', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.devolucao', 2), value: 'devolucoes', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.icms'), value: 'icms', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.ipi'), value: 'ipi', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.pis_cofins'), value: 'pisCofins', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base'), value: 'valorBase', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_nd_previsto'), value: 'valorNdPrevisto', cellClass: 'nowrap',
        },
      ],
      apuracaoDetalhes: [],
      expanded: [],
      indJustificativa: false,
      metas: [],
      idsApuracaoDetalhes: [],
      exibeModalRecebimentoParcial: false,
      apuracaoParcial: {},
    };
  },
};
