<template>
  <div class="pt-6">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $tc("title.resumo_recebimento_total", 1) }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="9">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="3">
                  <input-money
                    :label="$t('label.recebimento_atual')"
                    id="id-recebimento-atual"
                    v-model="apuracaoSelecionada.recebimentoAtual"
                    class="v-text-field"
                    disabled
                    :css-style="'min-width:140px'"
                  ></input-money>
                </v-col>
                <v-col cols="12" sm="3">
                  <tr>
                    <td>
                      <input-money
                        :label="$t('label.recebimento_definido')"
                        id="id-recebimento-definido"
                        v-model="apuracaoSelecionada.recebimentoDefinido"
                        class="v-text-field"
                        :rules="[rules.required, rules.valorPositivo, rules.valorMaiorQueEfetuado]"
                        :css-style="'min-width:140px'"
                      ></input-money>
                    </td>
                    <td
                      v-if="apuracaoSelecionada.justificativaAlterarRecebimento"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-on="{ ...tooltip, ...menu }"
                            >
                            <v-icon @click="visualizarJustificativa">
                              description
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('label.justificativa') }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            icon
                            v-on="{ ...tooltip, ...menu }"
                            >
                            <v-icon @click="abrirModalJustificativaAlteracaoValorDefinido()">
                              save
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('label.salvar') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </v-col>
                <v-col cols="12" sm="3">
                  <input-money
                    :label="$t('label.recebimento_efetuado')"
                    v-model="apuracaoSelecionada.valorRecebido"
                    class="v-text-field"
                    disabled
                    :css-style="'min-width:140px'"
                  ></input-money>
                </v-col>
                <v-col cols="12" sm="3">
                  <input-money
                    :label="$t('label.recebimento_pendente')"
                    v-model="apuracaoSelecionada.recebimentoPendente"
                    class="v-text-field"
                    disabled
                    :css-style="'min-width:140px'"
                  ></input-money>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <v-col class="align-self-center" cols="12" lg="3" v-if="periodoPossuiNotas && apuracaoSelecionada.recebimentoPendente > 0">
        <v-btn color="primary" x-large @click="$emit('AdicionarRecebimento')">
          {{ $t("label.adicionar_recebimento") }}
        </v-btn>
      </v-col>
    </v-row>
    <dialog-justificativa
      ref="dialogJustificativa"
      :justificativa="apuracaoSelecionada.justificativaAlterarRecebimento"
      @confirmar="confirmarAlteracaoValorRecebimento"
    >
    </dialog-justificativa>
    <confirm
      ref="apresentarJustificativa"
      :title="$t('title.justificativa')"
      :hideDisagree="true"
      :agreeLabel="$t('label.ok')"
      :message="apuracaoSelecionada.justificativaAlterarRecebimento"
    >
    </confirm>
  </div>
</template>
<script>
import InputMoney from '../../../shared-components/inputs/InputMoney';
import DialogJustificativa from '../../../shared-components/vuetify/dialog/DialogJustificativa';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  components: {
    InputMoney,
    DialogJustificativa,
    Confirm,
  },
  props: {
    apuracao: {},
    confirmarAlteracaoValor: Function,
  },
  watch: {
    apuracao: {
      immediate: true,
      handler(apuracao) {
        this.apuracaoSelecionada = apuracao;
      },
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQueEfetuado: (value) => (!!value && value >= this.apuracaoSelecionada.valorRecebido)
          || this.$t('errors.valor.menor_que_efetuado'),
      },
      apuracaoAcaoEdicaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      apuracaoSelecionada: this.apuracao,
    };
  },
  computed: {
    periodoPossuiNotas() {
      return this.apuracaoSelecionada.possuiNota;
    },
  },
  methods: {
    abrirModalJustificativaAlteracaoValorDefinido() {
      if (!this.$refs.form.validate()) return;
      this.$refs.dialogJustificativa.open();
    },
    confirmarAlteracaoValorRecebimento(justificativa) {
      this.apuracaoSelecionada.justificativaAlterarRecebimento = justificativa;
      this.apuracaoSelecionada.recebimentoPendente = this.apuracaoSelecionada.recebimentoDefinido - this.apuracaoSelecionada.valorRecebido;
      this.confirmarAlteracaoValor(justificativa, this.apuracaoSelecionada);
      this.$emit('ValorRecebimentoAlterado');
    },
    visualizarJustificativa() {
      this.$refs.apresentarJustificativa.open();
    },
  },
};
</script>
