<template>
  <v-form ref="form">
    <filtro-rapido
       v-model="abrirFiltro"
       ref="filtrosRapidos"
       id="ApuracaoContratoFinalizarLoteFiltros__filtro_rapido"
       v-if="carregouMetadados && carregouFiltrosPadroes"
       :campos-formulario="camposFormulario"
       :metadados="metadadosAgrupado"
       :ordenacao-campos="ordenacaoCampos"
       :mostrar-fixar-filtros="false"
       :formulario-filtros="false"
       :ignora-obrigatorios="false"
       grupo-expandido="Ação"
       :setar-filtro-padrao-ao-limpar="true"
       :aplicar-filtro-no-carregamento="false"
       :filtro-padrao="filtroPadrao"
       @FiltroRapido__AplicaFiltros="aplicarFiltros"
       @FiltroRapido_conteinerCarregado="$emit('ApuracaoContratoFinalizarLoteFiltros_conteinerCarregado')"
       @FiltroRapido__HabilitaIconBadge="(value) => $emit('FiltroRapido__HabilitaIconBadge', value)">
    </filtro-rapido>

  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { formatlastDayOfTheMonth, formatFirstDayOfTheMonth } from '@/produto/common/functions/date-utils';
import FiltroRapido from '../../shared-components/filtro-rapido/FiltroRapido';

export default {
  name: 'ApuracaoContratoFinalizarLoteFiltros',
  components: { FiltroRapido },
  props: {
    value: false,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      apuracaoContratoResource: this.$api.apuracaoContrato(this.$resource),

      abrirFiltro: false,
      carregouFiltrosPadroes: false,
      status: null,
      ordenacaoCampos: [
        {
          campo: 'tipo_acao',
          nomeGrupo: 'Ação',
        },
        {
          campo: 'grupo_fornecedor',
          nomeGrupo: 'Ação',
        },
        {
          campo: 'categoria',
          nomeGrupo: 'Ação',
          forcarGrupo: true,
        },
        {
          campo: 'data_inicio_apuracao',
          nomeGrupo: 'Período',
        },
        {
          campo: 'data_fim_apuracao',
          nomeGrupo: 'Período',
        },
        {
          campo: 'ocultar_apuracao_sem_nota',
          nomeGrupo: 'Outros',
          forcarGrupo: true,
        },
        {
          campo: 'ocultar_apuracoes_negativas',
          nomeGrupo: 'Outros',
          forcarGrupo: true,
        },
        {
          campo: 'ocultar_apuracoes_com_valor_zero',
          nomeGrupo: 'Outros',
          forcarGrupo: true,
        },
        {
          campo: 'apresentar_apuracoes_finalizadas',
          nomeGrupo: 'Outros',
          forcarGrupo: true,
        },
      ],
      filtroPadrao: {},
      menuProps: {
        closeOnClick: false,
        closeOnContentClick: false,
        openOnClick: false,
        maxHeight: 300,
        nudgeLeft: 150,
      },
      menuPropsExtensao: {
        nudgeLeft: 100,
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    camposFormulario() {
      return {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource
                .buscarConfiguradasEmTipoContrato({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
            menuProps: this.menuProps,
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'grupo_fornecedor',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
            menuProps: this.menuProps,
          },
          {
            labelCampo: 'data_inicio_apuracao',
            nomCampo: this.$t('label.data_inicio'),
            vlrObrigatorio: true,
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_apuracao',
            nomCampo: this.$t('label.data_fim'),
            vlrObrigatorio: true,
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'ocultar_apuracao_sem_nota',
            tipoCampo: 'BOOLEAN',
          },
          // {
          //   labelCampo: 'ocultar_apuracoes_negativas',
          //   tipoCampo: 'BOOLEAN',
          // },
          {
            labelCampo: 'ocultar_apuracoes_com_valor_zero',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'apresentar_apuracoes_finalizadas',
            tipoCampo: 'BOOLEAN',
          },
        ],
      };
    },
    carregouMetadados() {
      return this.getAcaoMetadado && this.getContratoMetadado;
    },
    metadadosAgrupado() {
      if (!this.carregouMetadados) {
        return {};
      }

      const metadados = { ...this.getContratoMetadado };
      metadados.dicionario = { ...this.getAcaoMetadado.dicionario };
      Object.assign(metadados.dicionario, this.getContratoMetadado.dicionario);

      metadados.mapaCamposDinamicos = { ...this.getAcaoMetadado.mapaCamposDinamicos };
      Object.assign(metadados.mapaCamposDinamicos, this.getContratoMetadado.mapaCamposDinamicos);

      metadados.mapaEntidades = {};
      Object.assign(metadados.mapaEntidades, this.getContratoMetadado.mapaEntidades);

      Object.keys(this.getAcaoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getAcaoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
        metadados.mapaEntidades[k].menuProps = this.menuPropsExtensao;
      });

      Object.keys(this.getContratoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getContratoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
        metadados.mapaEntidades[k].menuProps = this.menuPropsExtensao;
      });

      if (metadados.cabecalho) {
        metadados.cabecalho.concat(...this.getAcaoMetadado.cabecalho);
      } else {
        metadados.cabecalho = this.getAcaoMetadado.cabecalho;
      }
      return metadados;
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    aplicarFiltros(filtrosAplicados) {
      if (!this.$refs.form.validate() || !filtrosAplicados
        || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      filtrosAplicados.status = [this.status];
      filtrosAplicados.id_grupo_fornecedor = filtrosAplicados.grupo_fornecedor;
      this.$emit('ApuracaoContratoFinalizarLoteFiltros__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && (filtrosAplicados.data_inicio_apuracao
        || filtrosAplicados.data_fim_apuracao)) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio_apuracao, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim_apuracao, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_apuracao && !filtrosAplicados.data_fim_apuracao)
          || (!filtrosAplicados.data_inicio_apuracao && filtrosAplicados.data_fim_apuracao)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    buscarTipoAcao() {
      const idTipoAcao = this.$route.params.id_acao_tipo;
      if (!idTipoAcao) {
        return new Promise((res) => { res(); });
      }
      return this.tipoAcaoResource.buscarTipoAcao({ id: idTipoAcao })
        .then((res) => {
          const { id, nome } = { ...res.data };
          const tipoAcao = {
            id,
            nome,
          };
          this.filtroPadrao.tipo_acao = tipoAcao;
          this.filtroPadrao.id_acao_tipo = id;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarFornecedorContrato() {
      if (!this.$route.params.id_grupo_fornecedor) {
        return new Promise((res) => { res(); });
      }
      const { idContrato } = { ...this.$route.params };
      return this.planejamentoContratoResource.buscarGrupoFornecedorContrato({ idContrato })
        .then((response) => {
          this.filtroPadrao.grupo_fornecedor = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarDataApuracoes() {
      const data = new Date();
      if (this.$route.params.data_inicio_apuracao) {
        this.filtroPadrao.data_inicio_apuracao = this.$route.params.data_inicio_apuracao;
      } else {
        this.filtroPadrao.data_inicio_apuracao = formatFirstDayOfTheMonth(moment(data, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      }
      if (this.$route.params.data_fim_apuracao) {
        this.filtroPadrao.data_fim_apuracao = this.$route.params.data_fim_apuracao;
      } else {
        this.filtroPadrao.data_fim_apuracao = formatlastDayOfTheMonth(moment(data, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      }
    },
    preencherFiltroPadrao() {
      Promise
        .all([
          this.buscarDataApuracoes(),
        ])
        .then(() => {
          this.setarFiltroOcultarApuracaoSemNotaValorPadrao();
          this.setarFiltroOcultarApuracoesComValorZero();
          this.setarFiltroApresentarApuracoesFinalizadas();
          this.carregouFiltrosPadroes = true;
        });
    },
    preencherFiltroBreadCrumbs() {
      Promise
        .all([
          this.buscarDataApuracoes(),
          this.buscarTipoAcao(),
          this.buscarFornecedorContrato(),
        ])
        .then(() => {
          this.setarFiltroOcultarApuracaoSemNotaValorPadrao();
          this.setarFiltroOcultarApuracoesComValorZero();
          this.setarFiltroApresentarApuracoesFinalizadas();
          this.carregouFiltrosPadroes = true;
        });
    },
    setarFiltroOcultarApuracaoSemNotaValorPadrao() {
      this.filtroPadrao.ocultar_apuracao_sem_nota = true;
    },
    setarFiltroOcultarApuracoesNegativas() {
      this.filtroPadrao.ocultar_apuracoes_negativas = true;
    },
    setarFiltroOcultarApuracoesComValorZero() {
      this.filtroPadrao.ocultar_apuracoes_com_valor_zero = true;
    },
    setarFiltroApresentarApuracoesFinalizadas() {
      this.filtroPadrao.apresentar_apuracoes_finalizadas = true;
    },
  },
  beforeMount() {
    this.status = this.$router.currentRoute.params.status || 'AGUARDANDO_APURACAO';
    if (!this.$route.params.id_acao_tipo) {
      this.preencherFiltroPadrao();
    } else {
      this.preencherFiltroBreadCrumbs();
    }
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
};
</script>

<style scoped>

</style>
