<template>
  <div class="pt-6">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $tc("title.recebimento_efetuados", 2) }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="recebimentosParciais"
              class="elevation-0 my-3"
              :single-expand="true"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              hide-default-footer
              disable-pagination
              :items-per-page="-1"
            >
              <template v-slot:[`item.valorRecebimento`]="{ item }">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    {{ getMoney(item.valorRecebimento) }}
                    <span v-on="on" v-if="valorRecebimentoAlterado(item)">
                      <popover-generico
                        icone="warning"
                        label="label.valor_recebimento_tradelinks"
                        :valor="item.valorRecebimentoOriginal"
                        :is-valor-monetario="true"/>
                    </span>
                  </template>
                  <span>{{ $tc('label.valor_recebimento_tradelinks', 1) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.periodo`]="{ item }">
                {{ periodoFormatado(item) }}
              </template>
              <template v-slot:[`item.justificativa`]="{ item }">
                <tbody>
                  <td
                    class="justify-center px-0"
                    style="width: 1%; text-align: center"
                  >
                    <v-btn icon>
                      <v-icon
                        @click="visualizarJustificativa(item.justificativa)"
                      >
                        description
                      </v-icon>
                    </v-btn>
                  </td>
                </tbody>
              </template>
              <template v-slot:[`item.tipoUsoDefinido`]="{ item }">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    {{ item.tipoUsoDefinido }}
                    <span v-on="on" v-if="tipoUsoAlterado(item)">
                      <popover-generico
                        icone="warning"
                        label="label.tipo_uso_padrao"
                        :texto="item.tipoUsoPadrao"/>
                    </span>
                  </template>
                  <span>{{ $tc('label.tipo_uso_padrao', 1) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.valorBase`]="{ item }">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    {{ getMoney(item.valorBase) }}
                    <span v-on="on" v-if="valorBaseAlterado(item)">
                      <popover-generico
                        v-on="on"
                        icone="warning"
                        label="label.valor_base"
                        :valor="item.valorBaseOriginal"
                        :is-valor-monetario="true"/>
                    </span>
                  </template>
                  <span>{{ $tc('label.valor_base_tradelinks', 1) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <confirm
      ref="apresentarJustificativa"
      :title="$t('title.justificativa')"
      :hideDisagree="true"
      :agreeLabel="$t('label.ok')"
      :message="justificativaPagamentoParcial"
    >
    </confirm>
  </div>
</template>
<script>
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import { formatDateMonthYYYYShort } from '../../../common/functions/date-utils';
import { getMoney, capitalize } from '../../../common/functions/helpers';
import PopoverGenerico from '../../../shared-components/PopoverGenerico';

export default {
  components: {
    Confirm,
    PopoverGenerico,
  },
  data() {
    return {
      resourceApuracaoContratoParcial: this.$api.apuracaoContratoParcial(this.$resource),
      recebimentosParciais: [],
      headers: [
        {
          text: this.$t('label.valor_recebimento'),
          value: 'valorRecebimento',
          class: 'subtitle-2',
          cellClass: 'nowrap align-right',
        },
        {
          text: this.$tc('label.valor_base', 1),
          value: 'valorBase',
          class: 'subtitle-2',
          cellClass: 'nowrap align-right',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          class: 'subtitle-2 nowrap',
        },
        {
          text: this.$tc('label.data_contabilizacao', 1),
          value: 'dataContabilizacao',
          class: 'subtitle-2',
          cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.data_vencimento', 1),
          value: 'dataVencimento',
          class: 'subtitle-2',
          cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.tipo_uso_definido', 1),
          value: 'tipoUsoDefinido',
          class: 'subtitle-2',
          cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.justificativa', 1),
          value: 'justificativa',
          class: 'subtitle-2 nowrap align-center',
        },
        {
          text: this.$tc('label.nd_com_nota', 2),
          value: 'ndComNota',
          class: 'subtitle-2 align-center',
        },
        {
          text: this.$tc('label.criado_em', 1),
          value: 'dtaCriacao',
          class: 'subtitle-2 nowrap',
          cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.criado_por', 1),
          value: 'nomeUsuario',
          class: 'subtitle-2 nowrap',
        },
      ],
      justificativaPagamentoParcial: null,
    };
  },
  props: {
    apuracao: {},
  },
  watch: {
    apuracao: {
      immediate: true,
      handler() {
        if (this.apuracao && this.apuracao.idApuracao) {
          this.buscarRecebimentosParciais();
        }
      },
    },
  },
  methods: {
    getMoney,
    capitalize,
    buscarRecebimentosParciais() {
      const { idApuracao } = { ...this.apuracao };
      this.resourceApuracaoContratoParcial
        .buscarRecebimentosParciais({ idApuracao }, { idApuracao })
        .then((response) => {
          this.recebimentosParciais = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    periodoFormatado(item) {
      return `${capitalize(formatDateMonthYYYYShort(item.dataInicio, 'DD/MM/YYYY'))} à ${capitalize(formatDateMonthYYYYShort(item.dataFim, 'DD/MM/YYYY'))}`;
    },
    visualizarJustificativa(justificativa) {
      this.justificativaPagamentoParcial = justificativa;
      this.$refs.apresentarJustificativa.open();
    },
    tipoUsoAlterado(item) {
      return !!item.tipoUsoDefinido && !!item.tipoUsoPadrao && item.tipoUsoDefinido !== item.tipoUsoPadrao;
    },
    valorRecebimentoAlterado(item) {
      return item.valorRecebimento !== item.valorRecebimentoOriginal;
    },
    valorBaseAlterado(item) {
      return item.valorBase !== item.valorBaseOriginal;
    },
  },
};
</script>
