<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">{{ $t('title.finalizar_recebimento_especial') }}</v-card-title>
      <v-card-text class="justify">
        <v-container fluid>
          <v-row v-if="isFinalizarApuracao">
            <v-col cols="12" sm="12" class="pb-0">
              <span v-html="`${$t('message.finalizar_recebimento_parcial_finalizar_apuracao')}`" />
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-switch
                v-model="indFinalizaApuracao"
                color="primary"
                :label="$tc('label.finalizar_apuracao', 1)"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <span v-html="`${$t('message.finalizar_recebimento_parcial')}`" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="nao" color="primary darken-1" text @click.native="disagree">
          {{ $t('label.nao') }}
        </v-btn>
        <v-btn id="sim" color="primary darken-1" text @click.native="agree">
          {{ $t('label.sim') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    maxWidth: {
      type: String,
      default: '750',
    },
    isFinalizarApuracao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      indFinalizaApuracao: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    disagree() {
      this.close();
      this.$emit('disagree');
    },
    agree() {
      this.close();
      this.$emit('agree', this.indFinalizaApuracao);
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
