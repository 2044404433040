<template>
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" style="margin-top: -4em;">
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-row justify="end">
      <v-menu
        ref="tipo_uso_massa"
        v-if="podeAlterarTipoUso && contratos.length"
        :close-on-content-click="false"
        :nudge-width="400"
        v-model="menuAteraTipoUso"
        transition="scale-transition"
        offset-x
        left
        class="mr-3">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-on="{ ...tooltip, ...menu }"
                class="mr-3 mb-3"
                id="btn_tipo_uso"
                color="primary"
                :disabled="!podeAlterarTipoUso">
                {{ $t('label.tipo_uso') }}
              </v-btn>
            </template>
            <span>{{ $t('message.definir_todos_tipo_uso') }}</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-form ref="form" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    id="tipo-uso"
                    class="custom-autocomplete"
                    v-model="tipoUso.id"
                    :rules="[rules.required]"
                    :items="listaTiposUso"
                    :label="`${$tc('label.tipo_uso', 1)} *`"
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    item-text="nome"
                    item-value="id"
                    required
                    :clearable="true"
                    @click:append="() => triggerSelecao('tipo-uso')"
                    @click.native="(i) => buscaAutocomplete(i, buscarTiposUso)"
                  ></v-autocomplete>
                  <v-textarea
                    id="tipouso_justificativa"
                    index="1"
                    ref="textareaJustificativa"
                    name="tipouso_justificativa"
                    v-model="tipoUso.justificativaTipoUso"
                    auto-grow
                    rows="1"
                    :rules="[rules.required]"
                    :label="`${$t('label.justificativa')} *`"
                    :counter="500"
                    maxlength="500">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="cancelarAlteracaoTipoUso">{{ $t('label.cancelar') }}</v-btn>
            <v-btn
              @click.native="aplicarAlteracaoTipoUso"
              color="primary"
              :disabled="!existeContratoSelecionado">{{ $t('label.aplicar') }}</v-btn>
          </v-card-actions>
          <div class="pa-1" style="text-align: end;">
            <span v-if="!existeContratoSelecionado" style="color: red">{{ $t('message.selecione_um_contrato') }}</span>
          </div>
        </v-card>
      </v-menu>

      <v-tooltip
        left
        max-width="150"
        nudge-left="50"
        v-model="showTooltip">
        <template v-slot:activator="{ on }">
          <apuracao-contrato-finalizar-lote-filtros
            v-model="abrirFiltro"
            v-on="on"
            @ApuracaoContratoFinalizarLoteFiltros__AplicaFiltros="aplicarFiltros"
            @ApuracaoContratoFinalizarLoteFiltros_conteinerCarregado="abrirTooltip"
            @FiltroRapido__HabilitaIconBadge="habilitaIconBadge">
          </apuracao-contrato-finalizar-lote-filtros>
        </template>
        <span>{{ $t('message.selecione_tipo_acao_para_pesquisar') }}</span>
      </v-tooltip>
    </v-row>

    <v-row :class="tamanhoMargem" v-if="tipoAcao && !mostrarNaoEncontrado">
      <p class="font-weight-medium">{{this.$t('label.periodo')}}: </p>
      <p class="ml-1">{{periodoFormatado}}</p>
      <p class="font-weight-medium ml-3">{{this.$t('label.tipo_acao')}}: </p>
      <p class="ml-1">{{tipoAcao}}</p>
    </v-row>

    <v-container pt-6 fluid v-resize="onResize" v-if="contratosCarregados">
      <v-row>
        <v-expansion-panels
            accordion
            focusable
            v-model="panel"
            multiple
            :key="panelKey">
          <v-expansion-panel v-for="(contratoPaginado, idx) in contratosPaginados"
            :key="idx">
            <v-expansion-panel-header>
              <v-checkbox
                  v-model="contratoPaginado.selecionado"
                  v-if="!contratoPaginado.flagTodosAprovados"
                  hide-details
                  class="shrink pt-0 mt-0"
                  @click.stop="(e) => selecionarItem(contratoPaginado, e)">
              </v-checkbox>
              <div>
                <v-tooltip bottom v-if="!contratoPaginado.flagTodosAprovados">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="orange darken-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      report_problem
                    </v-icon>
                  </template>
                  <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="contratoPaginado.flagTodosAprovados">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="deep-orange accent-4"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>
                  <span>{{ $t('label.periodo_apurado') }}</span>
                </v-tooltip>
                <span class="subheading">
                  {{ tituloAgrupamento(contratoPaginado) }}
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <apuracao-contrato-finalizar-lote-tabela
                :contrato="contratoPaginado"
                :filtros="filtros"
                :ref="`tabela-${contratoPaginado.idContrato}`"
                :atualizar-recebimento-definido="atualizarRecebimentoDefinido"
                :selecionar-item="selecionarItem"
                :lista-tipos-uso="listaTiposUso"
                :aplicar-filtros="aplicarFiltros"
                :recarregarApuracoesLote="recarregarApuracoesLote"
                @tipoUsoAlterado="atualizarApuracaoTipoUso"
                @adicionarFornecedoresSelecionados="atualizarFornecedoresSelecionados"
                @ApuracaoContratoFinalizarLote__reRenderizarPanel="reRenderizarPanel">
              </apuracao-contrato-finalizar-lote-tabela>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-pagination
        v-if="contratosCarregados && pagination.totalPage >= pagination.rowsPerPage"
        v-model="pagination.page"
        :length="pages"
        :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
        class="py-6">
      </v-pagination>

      <p v-if="mostrarNaoEncontrado" class="ml-6 mt-4">{{ $t('label.tabela_sem_conteudo') }}</p>

      <v-footer
          v-bind="{fixed: true}"
          :style="`width: ${tamanhoRodape}px; background-color: #EEEEEE;`"
      >
        <v-spacer v-if="!xs"></v-spacer>
        <v-btn class="mr-2 my-auto"
         v-if="indFinalizarSelecionados"
        @click="finalizarSelecionados"
        :disabled="modalJustificativaOpen">
          {{ $t('label.finalizar_selecionados') }}
        </v-btn>
        <v-btn class="my-auto mr-3"
          color="primary"
          v-if="contratos.length"
          :disabled="modalJustificativaOpen"
          @click="finalizarTudo">
          {{ $t('label.finalizar_tudo') }}
        </v-btn>
      </v-footer>
    </v-container>

    <dialog-finalizar-apuracoes
      ref="modalFinalizarApuracoes"
      :apuracoes-inconsistentes="apuracoesInconsistentes"
      :apuracoes-valor-recebimento-inconsistentes="apuracoesValorRecebimentoInconsistentes"
      @agree="finalizarApuracoes"
      @cancelar="limparArraysFecharModalSelecionados">
    </dialog-finalizar-apuracoes>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/produto/common/functions/autocomplete-utils';
import ApuracaoContratoFinalizarLoteFiltros from './ApuracaoContratoFinalizarLoteFiltros';
import {
  buscarTotalApuradoPorContrato,
  buscarApuracaoFornecedorPorContrato,
  finalizarApuracaoLoteFornecedor,
} from '../../common/resources/apuracao/apuracao-contrato';
import { getMoney, getPercent, copyObject } from '../../common/functions/helpers';
import ApuracaoContratoFinalizarLoteTabela from './ApuracaoContratoFinalizarLoteTabela';
import DialogFinalizarApuracoes from './modals/DialogFinalizarApuracoes';

export default {
  name: 'ApuracaoContratoFinalizarLote',
  components: {
    DialogFinalizarApuracoes,
    ApuracaoContratoFinalizarLoteTabela,
    ApuracaoContratoFinalizarLoteFiltros,
  },
  data() {
    return {
      workflowApuracaoAcao: this.$api.workflowApuracaoAcao(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),

      resource: this.$api.apuracaoContrato(this.$resource),
      abrirFiltro: false,
      showTooltip: false,
      idContrato: null,
      contratos: [],
      filtros: {},
      eventoClickCheckbox: null,
      menuAteraTipoUso: false,
      tipoUso: {
        id: null,
        nomTipoUso: '',
        justificativaTipoUso: '',
      },
      listaTiposUso: [],
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      pagination: {
        page: 1,
        totalPage: 0,
        rowsPerPage: 10,
      },
      contratosCarregados: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      tipoAcao: null,
      apuracoesSelecionadas: [],
      apuracoesInconsistentes: [],
      apuracoesValorRecebimentoInconsistentes: [],
      indFinalizarTudo: false,
      tamanhoTela: window.innerWidth,
      tamanhoFiltros: 0,
      modalJustificativaOpen: false,
      panelKey: 0,
      habilitarIconBadge: false,
      objetoFormatado: {
        idApuracao: null,
        idsFornecedores: [],
      },
    };
  },
  watch: {
    abrirFiltro(val) {
      if (val) {
        this.showTooltip = false;
      }
    },
    'pagination.page': {
      handler() {
        this.buscarApuracoesContratosPaginados();
      },
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    tamanhoRodape() {
      return this.tamanhoTela - (this.abrirFiltro && this.lg ? this.tamanhoFiltros : 0);
    },
    contratosPaginados() {
      this.panel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      const listaPaginada = copyObject(this.contratos);
      const startFrom = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage;
      return listaPaginada.splice(startFrom, this.pagination.rowsPerPage);
    },
    pages() {
      return Math.ceil(this.pagination.totalPage / this.pagination.rowsPerPage);
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    mostrarNaoEncontrado() {
      return !this.pagination.totalPage && this.contratosCarregados;
    },
    podeAlterarTipoUso() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_ALTERA_TIPO_USO').length;
    },
    indFinalizarSelecionados() {
      return !this.indFinalizarTudo
        && (this.apuracoesSelecionadas.length
          || this.apuracoesInconsistentes.length
          || this.apuracoesValorRecebimentoInconsistentes.length);
    },
    existeContratoSelecionado() {
      return this.contratos.filter((c) => c.selecionado).length > 0;
    },
    tamanhoMargem() {
      return this.podeAlterarTipoUso ? 'ml-1' : 'ml-1 mt-5';
    },
    periodoFormatado() {
      const dataInicio = this.moment(this.filtros.data_inicio_apuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
      const dataFim = this.moment(this.filtros.data_fim_apuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return `${dataInicio} - ${dataFim}`;
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    getMoney,
    getPercent,
    copyObject,
    finalizarSelecionados() {
      this.apuracoesInconsistentes = [];
      this.apuracoesValorRecebimentoInconsistentes = [];

      this.apuracoesSelecionadas.forEach((a) => {
        if (this.apuracaoPossuiInconsistencia(a)) {
          this.apuracoesInconsistentes.push(a);
        }
        if (this.apuracaoPossuiInconsistenciaValorRecebimento(a)) {
          this.apuracoesValorRecebimentoInconsistentes.push(a);
        }
      });
      this.apuracoesSelecionadas = this.apuracoesSelecionadas.filter((a) => !this.apuracaoPossuiInconsistencia(a) && a.statusApuracao !== 'APROVADO');
      this.apuracoesSelecionadas = this.apuracoesSelecionadas.filter((a) => !this.apuracaoPossuiInconsistenciaValorRecebimento(a));
      setTimeout(() => this.$refs.modalFinalizarApuracoes.open(this.apuracoesSelecionadas));
    },
    desabilitaBotaoFinalizar(desabilitaBotao) {
      this.modalJustificativaOpen = desabilitaBotao;
    },
    apuracaoPossuiInconsistencia(apuracao) {
      return apuracao.recebimentoDefinido <= 0
       || (apuracao.indJustificativa && !apuracao.justificativaAlterarRecebimento)
       || (typeof apuracao.vlrRealizado === 'undefined' || apuracao.vlrRealizado === '')
       || !apuracao.possuiNota || !apuracao.existeAtingimento;
    },
    apuracaoPossuiInconsistenciaValorRecebimento(apuracao) {
      return apuracao.selecionado && !apuracao.indFornecedorFinalizado && apuracao.valorRecebido > 0
      && (apuracao.recebimentoDefinido - apuracao.valorRecebido !== 0);
    },
    async finalizarTudo() {
      this.indFinalizarTudo = true;
      const contratosSelecionados = [];
      this.contratos.forEach((c) => contratosSelecionados.push(c.idContrato));
      this.filtros.contratos_selecionados = contratosSelecionados;
      this.apuracoesSelecionadas = [];
      await this.buscarApuracoesParaFinalizar();
      await this.finalizarSelecionados();
    },
    finalizarApuracoes() {
      const filtros = this.prepararParametros();
      finalizarApuracaoLoteFornecedor(filtros, this.$resource)
        .then(() => {
          this.aplicarFiltros();
          this.$refs.modalFinalizarApuracoes.close();
          this.$toast(this.$tc('message.apuracao_finalizada_sucesso', 2));
        })
        .catch((err) => {
          this.$error(this, err);
          this.$refs.modalFinalizarApuracoes.close();
        });
    },
    prepararParametros() {
      const filtros = { ...this.filtros };
      if (this.apuracoesSelecionadas.length) {
        const idsApuracao = [];
        const apuracoesFornecedor = [];
        this.apuracoesSelecionadas.forEach((a) => {
          // Se a apuracao estiver selecionada o processo de finalizar continua com já estava
          // Caso contrário monta o array de objetos com a informação de fornecedor.
          if (a.selecionado) {
            idsApuracao.push(Number(a.idApuracao));
          } else {
            a.fornecedores.forEach((f) => {
              this.objetoFormatado = {
                idAcao: Number(a.idAcao),
                idApuracao: Number(a.idApuracao),
                idFornecedor: f.idFornecedor,
                idContrato: a.idContrato,
              };
              apuracoesFornecedor.push(this.objetoFormatado);
            });
          }
        });
        filtros.apuracoesSelecionadas = idsApuracao;
        filtros.apuracoesFornecedores = apuracoesFornecedor;
      }
      filtros.idTipoAcao = [filtros.id_acao_tipo];
      filtros.idGrupoFornecedor = filtros.id_grupo_fornecedor;
      filtros.dtaInicioApuracao = filtros.data_inicio_apuracao;
      filtros.dtaFimApuracao = filtros.data_fim_apuracao;

      delete filtros.id_acao_tipo;
      delete filtros.id_grupo_fornecedor;
      delete filtros.data_inicio_apuracao;
      delete filtros.data_fim_apuracao;
      delete filtros.grupo_fornecedor;
      delete filtros.numeroPagina;
      delete filtros.tamanhoPagina;

      return filtros;
    },
    selecionarItem(itemSelecionado, e) {
      if (e) e.stopPropagation();
      if (itemSelecionado.idFornecedor) {
        this.selecionarFornecedor(itemSelecionado);
      } else if (itemSelecionado.idApuracao) {
        this.selecionarApuracao(itemSelecionado);
      } else {
        this.selecionarContrato(itemSelecionado);
      }
    },
    selecionarContrato(itemSelecionado) {
      const { idContrato } = { ...itemSelecionado };
      const contrato = this.contratos.find((c) => c.idContrato === idContrato);
      const contratoPaginado = this.contratosPaginados.find((c) => c.idContrato === idContrato);
      contrato.apuracoes = contratoPaginado.apuracoes;
      if (itemSelecionado.selecionado) {
        contrato.selecionado = true;
        contrato.apuracoes.forEach((a) => {
          if (a.selecionado === false) {
            a.selecionado = true;
            if (a.detalhes && a.detalhes.length) {
              a.detalhes.forEach((fornecedor) => {
                fornecedor.selecionado = true;
              });
              a.fornecedores = a.detalhes;
            }
            this.apuracoesSelecionadas.push(a);
          }
        });
      } else {
        contrato.selecionado = false;
        contrato.apuracoes.forEach((a) => {
          const index = this.apuracoesSelecionadas.findIndex((apu) => apu.idApuracao === a.idApuracao);
          this.apuracoesSelecionadas.splice(index, 1);
          a.selecionado = false;
          if (a.detalhes && a.detalhes.length) {
            a.detalhes.forEach((fornecedor) => {
              fornecedor.selecionado = false;
            });
            a.fornecedores = a.detalhes;
          }
        });
      }
      this.reRenderizarTable(idContrato, itemSelecionado.selecionado);
    },
    selecionarApuracao(itemSelecionado) {
      const { idContrato, idApuracao } = itemSelecionado;
      const contrato = this.contratos.find((c) => c.idContrato === idContrato);
      const contratoPaginado = this.contratosPaginados.find((c) => c.idContrato === idContrato);
      contrato.apuracoes = contratoPaginado.apuracoes;
      if (itemSelecionado.selecionado) {
        contrato.apuracoes.forEach((a) => {
          if (a.idApuracao === idApuracao) {
            a.selecionado = true;
            if (itemSelecionado.detalhes && itemSelecionado.detalhes.length) {
              a.detalhes = itemSelecionado.detalhes;
            }
            if (a.detalhes) {
              a.detalhes.forEach((f) => {
                f.selecionado = true;
              });
              a.fornecedores = a.detalhes;
            }
            this.apuracoesSelecionadas.push(a);
          }
          let count = [];
          count = this.apuracoesSelecionadas.filter((apu) => apu.idContrato === idContrato);
          if (!contrato.selecionado && contrato.apuracoes.length === count.length) {
            contrato.selecionado = true;
          }
        });
      } else {
        contrato.selecionado = false;
        contrato.apuracoes.forEach((a) => {
          if (a.idApuracao === idApuracao) {
            a.selecionado = false;
            if (itemSelecionado.detalhes && itemSelecionado.detalhes.length) {
              a.detalhes = itemSelecionado.detalhes;
            }
            if (a.detalhes) {
              a.detalhes.forEach((el) => {
                el.selecionado = false;
              });
              a.fornecedores = a.detalhes;
            }
          }
        });
        const index = this.apuracoesSelecionadas.findIndex((a) => a.idApuracao === idApuracao);
        this.apuracoesSelecionadas.splice(index, 1);
      }
      this.reRenderizarTableApuracao(idContrato);
    },
    selecionarFornecedor(itemSelecionado) {
      const {
        idApuracao,
        idContrato,
      } = itemSelecionado;
      const contrato = this.contratos.find((c) => c.idContrato === idContrato);
      const contratoPaginado = this.contratosPaginados.find((c) => c.idContrato === idContrato);
      contrato.apuracoes = contratoPaginado.apuracoes;
      if (itemSelecionado.selecionado) {
        contrato.apuracoes.forEach((apuracao) => {
          if (apuracao.idApuracao === idApuracao.toString() && apuracao.detalhes && apuracao.detalhes.length) {
            this.selecionarFornecedorApuracao(contrato, apuracao, itemSelecionado);
          }
        });
      } else {
        contrato.apuracoes.forEach((apuracao) => {
          if (apuracao.idApuracao === idApuracao.toString() && apuracao.detalhes && apuracao.detalhes.length) {
            this.removerSelecaoFornecedorApuracao(contrato, apuracao, itemSelecionado);
          }
        });
      }
      this.reRenderizarTableApuracao(idContrato);
    },
    selecionarFornecedorApuracao(contrato, apuracao, itemSelecionado) {
      const {
        idContrato,
        idApuracao,
        idFornecedor,
      } = itemSelecionado;
      apuracao.detalhes.forEach((fornecedor) => {
        if (fornecedor.idFornecedor === idFornecedor) {
          fornecedor.selecionado = true;
          // verifica se a apuração ja esta na lista de selecionadas, se não estiver add, caso contrário só add o fornecedor na lista
          const indexApuracaoJaInserida = this.apuracoesSelecionadas.findIndex((el) => Number(el.idApuracao) === idApuracao);
          if (indexApuracaoJaInserida === -1) {
            apuracao.fornecedores = [];
            apuracao.fornecedores.push(fornecedor);
            this.apuracoesSelecionadas.push(apuracao);
          } else {
            this.apuracoesSelecionadas[indexApuracaoJaInserida].fornecedores.push(fornecedor);
          }
          // verifica se deve ou não selecionar a apuracao e contrato do fornecedor
          let countFornecedores = [];
          let countApuracoes = [];
          countFornecedores = this.apuracoesSelecionadas.filter((a) => Number(a.idApuracao) === idApuracao)[0].fornecedores;
          // se a quantidade de fornecedores da apuracao for igual a quantidade de fornecedores
          // do array de selecionadas é necessário flegar a apuracao
          if (apuracao.detalhes.length === countFornecedores.length) {
            this.apuracoesSelecionadas.forEach((a) => {
              if (Number(a.idApuracao) === idApuracao) {
                a.selecionado = true;
              }
            });
            apuracao.selecionado = true;
          }
          // verifica se todas as apuracoes do contrato estão selecionadas, se tiver flega o contrato tbm
          countApuracoes = this.apuracoesSelecionadas.filter((a) => a.idContrato === idContrato);
          if (!contrato.selecionado && contrato.apuracoes.length === countApuracoes.length && apuracao.selecionado) {
            contrato.selecionado = true;
          }
        }
      });
      this.reRenderizarTableApuracao(idContrato);
    },
    removerSelecaoFornecedorApuracao(contrato, apuracao, itemSelecionado) {
      const {
        idApuracao,
        idFornecedor,
      } = itemSelecionado;
      apuracao.detalhes.forEach((fornecedor) => {
        if (fornecedor.idFornecedor === idFornecedor) {
          fornecedor.selecionado = false;
        }
      });

      this.apuracoesSelecionadas.forEach((el) => {
        if (Number(el.idApuracao) === idApuracao) {
          // verifica dentro das apuracoes selecionadas o fornecedor desmarcado, e retira da lista de fornecedores
          const index = el.fornecedores.findIndex((f) => f.idFornecedor === idFornecedor);
          if (index !== -1) {
            el.fornecedores.splice(index, 1);
          }
          apuracao.fornecedores = el.fornecedores;

          let countFornecedor = [];
          // verifica a quantidade de fornecedores selecionados. Caso 1 fornecedor sair já é necessário desflegar o contrato e apuração
          countFornecedor = this.apuracoesSelecionadas.filter((a) => Number(a.idApuracao) === idApuracao)[0].fornecedores;
          if (apuracao.detalhes.length !== countFornecedor.length) {
            apuracao.selecionado = false;
            contrato.selecionado = false;
            // se não ficar nenhum fornecedor na lista de selecionados, tbm retiro a apuração da lista
            if (!el.fornecedores.length) {
              const ind = this.apuracoesSelecionadas.findIndex((a) => Number(a.idApuracao) === idApuracao);
              this.apuracoesSelecionadas.splice(ind, 1);
            }
          }
        }
      });
    },
    reRenderizarTableApuracao(idContrato) {
      const nomRef = 'tabela-'.concat(idContrato);
      if (this.$refs[nomRef] && this.$refs[nomRef][0]) {
        this.$refs[nomRef][0].reRenderizarTable();
      }
    },
    aplicarFiltros(paramFiltros) {
      this.limparArraysControladores();
      let filtros;
      this.contratosCarregados = false;
      this.contratos = [];
      if (!paramFiltros || paramFiltros.manterPagina) {
        filtros = {
          ...this.filtros,
        };
      } else {
        filtros = { ...paramFiltros };
      }
      if (!paramFiltros || !paramFiltros.manterPagina) {
        this.pagination.page = 1;
      }
      filtros.numeroPagina = 1;
      filtros.tamanhoPagina = 1000;
      this.buscarContratos(filtros);
      this.abrirFiltro = false;
    },
    limparArraysControladores() {
      this.apuracoesSelecionadas = [];
      this.apuracoesInconsistentes = [];
      this.indFinalizarTudo = false;
    },
    limparArraysFecharModalSelecionados() {
      this.apuracoesSelecionadas = [];
      this.apuracoesInconsistentes = [];
      this.indFinalizarTudo = false;
      this.contratos.forEach((item) => {
        item.selecionado = false;
        if (item.apuracoes.length) {
          for (let index = 0; index < item.apuracoes.length; index += 1) {
            const element = item.apuracoes[index];
            if (element.selecionado) {
              element.selecionado = false;
            }
            if (element.detalhes && element.detalhes.length) {
              for (let indexForn = 0; indexForn < element.detalhes.length; indexForn += 1) {
                const elementForn = element.detalhes[indexForn];
                if (elementForn.selecionado) {
                  elementForn.selecionado = false;
                }
              }
              element.fornecedores = [];
            }
          }
        }
        this.reRenderizarTable(item.idContrato);
      });
    },
    buscarContratos(filtros) {
      this.buscarTipoAcao(filtros.id_acao_tipo);
      this.showTooltip = false;
      if (!filtros.status.includes('APROVADO')) {
        filtros.status.push('APROVADO');
      }
      this.filtros = filtros;
      this.filtros.is_apuracao_antiga = true;
      return buscarTotalApuradoPorContrato(filtros, this.$resource)
        .then((response) => {
          this.panel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
          const contratos = response.data.resposta;
          this.limparArraysControladores();
          this.pagination.page = 1;
          this.pagination.totalPage = contratos.length;
          if (this.pagination.page > this.pages) {
            this.pagination.page = this.pages;
          }
          const promises = [];
          contratos.forEach((item, index) => {
            item.selecionado = false;
            const indexInicio = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage,
              indexFim = indexInicio + this.pagination.rowsPerPage - 1;
            if (index >= indexInicio && index <= indexFim) {
              promises.push(this.buscarApuracoesLote(item));
            }
          });
          Promise.all(promises).then(() => {
            this.contratos = contratos;
            this.contratosCarregados = true;
            this.reRenderizarPanel();
          });
        })
        .catch((err) => {
          this.$error(this, err);
          this.contratosCarregados = true;
        });
    },
    buscarApuracoesLote(contrato, force = false) {
      if (force
        || contrato.apuracoes === null
        || contrato.apuracoes === undefined
        || !contrato.apuracoes.length) {
        const { idContrato } = contrato;
        const params = { ...this.filtros };
        params.cod_contrato = idContrato;
        params.tamanhoPagina = 50000;
        params.numeroPagina = 0;
        if (!params.status.includes('APROVADO')) {
          params.status.push('APROVADO');
        }
        return buscarApuracaoFornecedorPorContrato(params, this.$resource)
          .then((response) => {
            contrato.apuracoes = response.data.resposta || [];
            contrato.flagTodosAprovados = false;
            let qtdAprovado = 0;
            contrato.apuracoes.forEach((apuracao) => {
              if (apuracao.statusApuracao === 'APROVADO') {
                qtdAprovado += 1;
                if (qtdAprovado === contrato.apuracoes.length) {
                  this.contratosPaginados.forEach((contratoPaginado) => {
                    if (contratoPaginado.idContrato === contrato.idContrato) {
                      contratoPaginado.flagTodosAprovados = true;
                    }
                  });
                  contrato.flagTodosAprovados = true;
                }
              } else {
                apuracao.selecionado = false;
                this.preencherRecebidoOriginal(apuracao);
              }
            });
            this.reRenderizarTable(idContrato);
            this.reRenderizarPaginacao();
            this.pararEsperar();
          })
          .catch((err) => {
            this.$error(this, err);
            this.pararEsperar();
          });
      }
      return new Promise((res) => { res(); });
    },
    reRenderizarPaginacao() {
      this.pagination.rowsPerPage += 1;
      this.pagination.rowsPerPage -= 1;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 3E3);
    },
    preencherRecebidoOriginal(apuracao) {
      if (!apuracao) return;
      apuracao.recebimentoDefinidoOriginal = apuracao.recebimentoDefinido;
    },
    alterarTipoUsoNovo(contrato, tipoUso = null) {
      if (contrato && contrato.selecionado && tipoUso != null) {
        contrato.apuracoes.forEach((apu) => {
          apu.idTipoUsoNovo = tipoUso.id;
          apu.justificativa = tipoUso.justificativaTipoUso;
        });
      }
    },
    abrirTooltip() {
      setTimeout(() => {
        this.showTooltip = true;
      }, 1000);
      this.tamanhoFiltros = document.getElementById('ApuracaoContratoFinalizarLoteFiltros__filtro_rapido').offsetWidth;
    },
    cancelarAlteracaoTipoUso() {
      this.menuAteraTipoUso = false;
      this.resetarTipoUso();
    },
    aplicarAlteracaoTipoUso() {
      this.menuAteraTipoUso = false;
      this.contratos
        .filter((c) => c.selecionado)
        .forEach((contrato) => {
          this.alterarTipoUsoNovo(contrato, this.tipoUso);
        });
      this.salvarTipoUsoEmLote()
        .then(() => {
          this.$toast(this.$t('message.tipo_uso_alterado_sucesso'));
          this.contratos
            .filter((contrato) => contrato.selecionado)
            .forEach((contrato) => {
              const { idContrato } = contrato;
              this.reRenderizarTable(idContrato, contrato.selecionado);
            });
          this.limparArraysControladores();
          this.resetarTipoUso();
          this.abrirPaineisSelecionados();
          this.reRenderizarPanel();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarTipoUsoEmLote() {
      const promises = [];
      this.contratos
        .filter((c) => c.selecionado)
        .forEach((contrato) => {
          contrato.apuracoes
            .filter((apu) => apu.idTipoUsoNovo)
            .forEach((apuracao) => {
              const { idApuracao } = apuracao;
              const promise = this.workflowApuracaoAcao.salvarApuracaoAcaoContratoTipoUso({ idApuracao },
                {
                  tipo_uso: this.tipoUso.id,
                  justificativa_tipo_uso: this.tipoUso.justificativaTipoUso,
                })
                .catch((err) => {
                  this.$error(this, err);
                });
              promises.push(promise);
            });
        });
      return Promise.all(promises);
    },
    buscarTiposUso() {
      const params = {
        indTipoUsoContrato: true,
      };
      this.workflowAcaoMagaluResource
        .buscarTiposUso(params)
        .then((res) => {
          this.listaTiposUso = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirPaineisSelecionados() {
      this.panel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    },
    resetarTipoUso() {
      this.tipoUso = {
        nomTipoUso: '',
        justificativaTipoUso: '',
      };
    },
    tituloAgrupamento(contrato) {
      const labels = this.buscaLabels();
      let titulo = ''.concat(contrato.idContrato)
        .concat(' - ')
        .concat(contrato.origemContrato)
        .concat(' - [')
        .concat(contrato.tipoContrato)
        .concat(']');
      if (contrato.calculoBonificacao) {
        titulo = titulo.concat(labels.get(contrato.calculoBonificacao));
      }
      titulo = titulo.concat(labels.get(contrato.baseCalculo))
        .concat(labels.get('descontos'))
        .concat(this.formatarDescontos(contrato.descontos, labels))
        .concat(labels.get('valor_definido'))
        .concat(this.getMoney(contrato.vlrRecebimento));

      return titulo;
    },
    buscarTipoAcao(id) {
      this.tipoAcaoResource.buscarTipoAcao({ id })
        .then((res) => {
          this.tipoAcao = res.data.nome;
        });
    },
    buscaLabels() {
      const map = new Map();
      map.set('descontos', ` - ${this.$tc('label.desconto', 2)}: `);
      map.set('VALOR_LIQUIDO', ` - ${this.$tc('label.valor_net')}`);
      map.set('VALOR_BRUTO', ` - ${this.$tc('label.valor_bruto')}`);
      map.set('SELLIN', ` - ${this.$tc('label.sellin')}`);
      map.set('SELLOUT', ` - ${this.$tc('label.sellout')}`);
      map.set('valor_definido', ` - ${this.$tc('label.valor_definido')}: `);
      map.set('DEVOLUCOES', `${this.$tc('label.devolucao', 2)}`);
      map.set('ICMS', `${this.$tc('label.icms')}`);
      map.set('IPI', `${this.$tc('label.ipi')}`);
      map.set('PIS_COFINS', `${this.$tc('label.pis_cofins')}`);

      return map;
    },
    buscarApuracoesParaFinalizar(idContrato = null) {
      const params = { ...this.filtros };
      delete params.numeroPagina;
      params.tamanhoPagina = 1000;

      if (idContrato != null) {
        params.cod_contrato = idContrato;
      }

      return buscarApuracaoFornecedorPorContrato(params, this.$resource)
        .then((response) => {
          const apuracoes = response.data.resposta || [];
          apuracoes.forEach((apuracao) => {
            const existeApuracao = this.apuracoesSelecionadas.find((a) => a.idApuracao === apuracao.idApuracao);
            if (!existeApuracao && apuracao.statusApuracao !== 'APROVADO') {
              apuracao.selecionado = true;
              this.apuracoesSelecionadas.push(apuracao);
            }
          });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formatarDescontos(descontos, labels) {
      if (descontos === null || descontos === undefined) {
        descontos = '';
      }
      return descontos.split('; ').map((desconto) => labels.get(desconto)).join('; ');
    },
    atualizarRecebimentoDefinido(idContrato, vlrRecebimento, idApuracao) {
      const contrato = this.contratos.find((c) => c.idContrato === idContrato);
      contrato.vlrRecebimento = vlrRecebimento;
      contrato.apuracoes.forEach((apuracao) => {
        if (apuracao.idApuracao === idApuracao) {
          apuracao.recebimentoDefinido = vlrRecebimento;
        }
      });
      return this.buscarApuracoesLote(contrato, true);
    },
    atualizarApuracaoTipoUso(idContrato, apuracaoAlterada) {
      const contrato = this.contratos.find((c) => c.idContrato === idContrato);
      const apuracao = contrato.apuracoes.find((a) => a.idApuracao === apuracaoAlterada.idApuracao);
      apuracao.idTipoUsoNovo = apuracaoAlterada.idTipoUsoNovo;
      apuracao.justificativa = apuracaoAlterada.justificativa;
    },
    atualizarFornecedoresSelecionados(idApuracao, fornecedores) {
      const copiaFornecedor = copyObject(fornecedores);
      this.apuracoesSelecionadas.forEach((a) => {
        if (a.idApuracao === idApuracao) {
          a.fornecedores = copiaFornecedor;
        }
      });
    },
    reRenderizarPanel(idContrato = null, expanded = null) {
      this.panelKey += 1;
      if (idContrato !== null && expanded !== null) {
        const nomRef = 'tabela-'.concat(idContrato);
        setTimeout(() => {
          this.$refs[nomRef][0].setarApuracoesExpandidas(expanded);
        }, 300);
      }
    },
    reRenderizarTable(idContrato, selecionado = false) {
      const nomRef = 'tabela-'.concat(idContrato);
      if (this.$refs[nomRef] && this.$refs[nomRef][0]) {
        this.$refs[nomRef][0].alterarCheckboxApuracoes(selecionado);
        this.$refs[nomRef][0].reRenderizarTable();
      }
    },
    onResize() {
      this.tamanhoTela = window.innerWidth;
    },
    buscarApuracoesContratosPaginados() {
      const promises = [],
        indexInicio = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage,
        indexFim = indexInicio + this.pagination.rowsPerPage - 1,
        contratosPaginacao = this.contratos
          .filter((contrato, index) => !contrato.apuracoes && index >= indexInicio && index <= indexFim) || [];
      contratosPaginacao
        .forEach((contrato) => {
          promises.push(this.buscarApuracoesLote(contrato));
        });
      if (promises.length) {
        Promise.all(promises).then(() => {
          contratosPaginacao
            .forEach((contrato) => {
              const contratoPaginado = this.contratosPaginados.find((c) => c.idContrato === contrato.idContrato);
              contratoPaginado.apuracoes = contrato.apuracoes;
            });
          this.reRenderizarPanel();
        });
      }
    },
    recarregarApuracoesLote(idContrato) {
      const contrato = this.contratos.find((c) => c.idContrato === idContrato);
      this.limparArraysControladores();
      return this.buscarApuracoesLote(contrato, true);
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
  },
  mounted() {
    this.buscarTiposUso();
    const params = { possuiFiltro: false };

    if (this.$route.params.id_acao_tipo) {
      this.idContrato = null;
      params.id_acao_tipo = this.$route.params.id_acao_tipo;
      if (this.$route.params.data_fim_apuracao) {
        params.data_fim_apuracao = this.$route.params.data_fim_apuracao;
      }

      if (this.$route.params.data_inicio_apuracao) {
        params.data_inicio_apuracao = this.$route.params.data_inicio_apuracao;
      }

      if (this.$route.params.id_grupo_fornecedor) {
        params.id_grupo_fornecedor = this.$route.params.id_grupo_fornecedor;
      }

      if (this.$route.params.status) {
        params.status = [this.$route.params.status];
      }

      params.possuiFiltro = true;
    }

    if (params.possuiFiltro) {
      this.aplicarFiltros(params);
    }
  },
};
</script>
