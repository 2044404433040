<template>
  <div class="pt-6">
    <v-row>
      <v-col>
        <div>
          <div class="title-float">
            {{ $tc("title.adicionar_recebimento", 1) }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-text>
            <v-form
              ref="formCalcular"
              lazy-validation
              autocomplete="off"
            >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-menu
                    ref="data_fim"
                    :close-on-content-click="false"
                    v-model="menuDataInicio"
                    id="data_fim"
                    name="data_fim"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :rules="[rules.requiredDataInicio]"
                        v-model="dataInicioApuracaoFormatada"
                        :label="`${$t('label.data_inicio')} *`"
                        readonly
                        clearable
                        prepend-inner-icon="event"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      color="primary"
                      type="month"
                      :min="dataInicioApuracao"
                      :max="dataFimApuracao"
                      :allowed-dates="mesesPagamentoParcialValidos"
                      v-model="apuracaoParcial.dataInicio"
                      @input="menuDataInicio = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-menu
                    ref="data_fim"
                    :close-on-content-click="false"
                    v-model="menuDataFim"
                    id="data_fim"
                    name="data_fim"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :rules="[rules.requiredDataFim]"
                        v-model="dataFimApuracaoFormatada"
                        :label="`${$t('label.data_fim')} *`"
                        readonly
                        clearable
                        prepend-inner-icon="event"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      color="primary"
                      type="month"
                      :min="apuracaoParcial.dataInicio || dataInicioApuracao"
                      :max="dataFimApuracao"
                      :allowed-dates="mesesPagamentoParcialValidos"
                      v-model="apuracaoParcial.dataFim"
                      @input="menuDataFim = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="align-self-center" cols="12" sm="6" md="4" lg="3">
                  <v-btn color="primary" @click="buscarApuracaoParcial">{{
                    $t("label.calcular")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-form
              ref="form"
              lazy-validation
              autocomplete="off"
              v-if="habilitarFormulario"
            >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    :label="$t('label.verba')"
                    id="id-recebimento-atual"
                    :value="formatarVerba(apuracaoParcial)"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <input-money
                      ref="valorBaseInput"
                      :label="$t('label.valor_base')"
                      id="id-recebimento-definido"
                      :append-icon="showIcon() ? 'refresh' : ''"
                      :clickAppendIcon="clickAppendIcon"
                      v-model="valorBaseDigitado"
                      :hint="getMoney(apuracaoParcial.valorBase)"
                      :persistent-hint="true"/>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <input-money
                    id="id-valo-recebimento"
                    :label="`${$t('label.valor_recebimento')} *`"
                    :rules="[rules.valorMaiorZero, rules.valorMaiorRecebimento]"
                    v-model="valorDigitado"
                    :hint="isVerbaFixa
                      ? getMoney(apuracaoParcial.vlrConfApuracao) : getMoney(apuracaoParcial.valorRecebimento)"
                    :persistent-hint="true"
                    class="v-text-field"
                  ></input-money>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-menu
                    :close-on-content-click="true"
                    v-model="menuDataContabilizacao"
                    id="data_contabilizacao"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        :rules="[rules.required]"
                        readonly
                        clearable
                        v-model="dataContabilizacaoFormatada"
                        :label="`${$t('label.data_contabilizacao')} *`"
                        prepend-inner-icon="event"
                        @click:clear="limpaDataContabilizacao()"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      color="primary"
                      v-model="apuracaoParcial.dataContabilizacao"
                      @input="setaDataContabilizacaoFormatada()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-menu
                    :close-on-content-click="true"
                    v-model="menuDataVencimento"
                    :disabled="!apuracaoParcial.dataContabilizacao"
                    id="data_vencimento"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="dataVencimentoFormatada"
                        :label="$t('label.data_vencimento')"
                        readonly
                        :clearable="!!apuracaoParcial.dataContabilizacao"
                        :disabled="!apuracaoParcial.dataContabilizacao"
                        prepend-inner-icon="event"
                        :hint="`${$t('label.prazo')} = ${apuracaoParcial.prazo} dias`"
                        :persistent-hint="true"
                        @click:clear="limpaDataVencimento()"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      color="primary"
                      v-model="apuracaoParcial.dataVencimento"
                      @input="setaDataVencimentoFormatada()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-select
                    id="tipo-uso"
                    v-model="tipoUso"
                    :items="listaTiposUso"
                    item-text="nome"
                    item-value="id"
                    return-object
                    :label="`${$t('label.tipo_uso_definido')} *`"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col sm="4">
                  <v-textarea
                    id="justificativa-recebimento-parcial"
                    v-model="apuracaoParcial.justificativa"
                    rows="1"
                    auto-grow
                    :rules="[rules.required]"
                    :label="`${$t('label.justificativa')} *`"
                    :counter="500"
                    maxlength="500"
                  >
                  </v-textarea>
                </v-col>
                <v-col sm="4">
                  <v-switch
                    :label="$t('label.impressao_nd_com_historico_notas')"
                    color="primary"
                    v-model="indImpressaoNdComHistorico"
                    class="mt-3"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar">{{ $t("label.cancelar") }}</v-btn>
            <v-btn
              color="primary"
              text
              @click="abrirModalConfirmacao"
              >{{ $t("label.finalizar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <modal-confirmacao-finalizar-recebimento
      ref="modalConfirmacaoFinalizarRecebimento"
      :is-finalizar-apuracao="podeFinalizarApuracao"
      @agree="finalizar">
    </modal-confirmacao-finalizar-recebimento>
  </div>
</template>
<script>
import moment from 'moment';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import {
  parseDateYYYYMMDD,
  formatDateMonthYYYY,
  formatlastDayOfTheMonth,
  formatFirstDayOfTheMonth,
} from '../../../common/functions/date-utils';
import { getMoney, getPercent, capitalize } from '../../../common/functions/helpers';
import ModalConfirmacaoFinalizarRecebimento from './ModalConfirmacaoFinalizarRecebimento';

export default {
  components: {
    InputMoney,
    ModalConfirmacaoFinalizarRecebimento,
  },
  props: {
    apuracao: {},
  },
  watch: {
    apuracao: {
      immediate: true,
      handler() {
        if (this.apuracao.idApuracao !== null && this.apuracao.idApuracao !== undefined) {
          this.validarDatasPermitidas();
        }
      },
    },
  },
  data() {
    return {
      resourceApuracaoContratoParcial: this.$api.apuracaoContratoParcial(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      menuDataInicio: false,
      menuDataFim: false,
      menuDataContabilizacao: false,
      menuDataVencimento: false,
      rules: {
        requiredDataInicio: () => !!this.apuracaoParcial.dataInicio || this.$t('message.campo_obrigatorio'),
        requiredDataFim: () => !!this.apuracaoParcial.dataFim || this.$t('message.campo_obrigatorio'),
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorMaiorZero: (value) => value > 0 || this.$t('message.valor_maior_zero'),
        valorMaiorRecebimento: (value) => value <= this.apuracao.recebimentoPendente || this.$t('message.valor_maior_recebimento'),
      },
      habilitarFormulario: false,
      apuracaoParcial: {},
      listaTiposUso: null,
      tipoUso: {},
      valorDigitado: 0,
      indImpressaoNdComHistorico: true,
      dataInicioProxApuracao: null,
      dataInicioApuracao: null,
      dataFimApuracao: null,
      valorBaseDigitado: 0,
      valorBaseOriginal: 0,
      dataContabilizacaoFormatada: null,
      dataVencimentoFormatada: null,
      mesesValidos: [],
      mensagemConfirmacaoFinalizarRecebimento: '',
    };
  },
  computed: {
    dataInicioApuracaoFormatada: {
      get() {
        return capitalize(formatDateMonthYYYY(this.apuracaoParcial.dataInicio));
      },
      set(newValue) {
        this.apuracaoParcial.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dataFimApuracaoFormatada: {
      get() {
        return capitalize(formatDateMonthYYYY(this.apuracaoParcial.dataFim));
      },
      set(newValue) {
        this.apuracaoParcial.dataFim = parseDateYYYYMMDD(newValue);
      },
    },
    isVerbaFixa() {
      return this.apuracao.formaBonificacao === 'FIXO';
    },
    periodoPossuiNotas() {
      return this.apuracao.possuiNota && this.apuracaoParcial.valorBase > 0;
    },
    podeFinalizarApuracao() {
      if (!this.apuracaoParcial.dataInicio || !this.apuracaoParcial.dataFim) {
        return false;
      }
      const isVigenciaFinalizada = moment().isAfter(moment(this.apuracao.dataFim, 'DD/MM/YYYY')),
        isRecebimentoPendenteZerado = this.apuracao.recebimentoPendente - this.valorDigitado === 0,
        mesesMoment = this.mesesValidos.map((mes) => moment(mes, 'YYYY-MM')),
        menorMes = moment.min(mesesMoment),
        maiorMes = moment.max(mesesMoment),
        anoMesInicioSelecionado = moment(this.apuracaoParcial.dataInicio, 'YYYY-MM'),
        anoMesFimSelecionado = moment(this.apuracaoParcial.dataFim, 'YYYY-MM');
      let mesesFaltantesForamSelecionados = false;

      if (anoMesInicioSelecionado.isSame(menorMes) && anoMesFimSelecionado.isSame(maiorMes)) {
        mesesFaltantesForamSelecionados = true;
      }
      return isVigenciaFinalizada && isRecebimentoPendenteZerado && mesesFaltantesForamSelecionados;
    },
  },
  methods: {
    getMoney,
    getPercent,
    capitalize,
    buscarApuracaoParcial() {
      if (!this.$refs.formCalcular.validate()) return;
      const parametros = {
        dataInicio: formatFirstDayOfTheMonth(moment(this.apuracaoParcial.dataInicio, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
        dataFim: formatlastDayOfTheMonth(moment(this.apuracaoParcial.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
        idApuracao: this.apuracao.idApuracao.split(',')[0],
      };
      this.resourceApuracaoContratoParcial
        .buscarCalculoApuracaoParcial(parametros)
        .then((response) => {
          this.apuracaoParcial = { ...this.apuracaoParcial, ...response.data };
          this.indImpressaoNdComHistorico = true;
          this.habilitarFormulario = !!this.apuracaoParcial;
          if (!this.habilitarFormulario) {
            this.$toast(this.$t('message.sem_dados_periodo'));
          }
          if (!this.apuracaoParcial.verba) {
            this.apuracaoParcial.verba = this.apuracao.verba;
          }
          this.valorDigitado = this.isVerbaFixa
            ? this.apuracaoParcial.vlrConfApuracao : this.apuracaoParcial.valorRecebimento;
          this.valorBaseDigitado = this.apuracaoParcial.valorBase;
          this.setTipoUso();
          this.inicializaDataContabilizacao();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    setTipoUso() {
      const { nomeTipoUso } = { ...this.apuracaoParcial };
      const tipoUsoApuracao = this.listaTiposUso.find((tipoUso) => tipoUso.nome === nomeTipoUso);
      this.tipoUso = tipoUsoApuracao;
    },
    buscarTiposUso() {
      const params = {
        indTipoUsoContrato: true,
      };
      this.workflowAcaoMagaluResource
        .buscarTiposUso(params)
        .then((res) => {
          this.listaTiposUso = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.resetarFormulario();
    },
    abrirModalConfirmacao() {
      if (!this.$refs.form.validate()) return;
      this.$refs.modalConfirmacaoFinalizarRecebimento.open();
    },
    finalizar(indFinalizaApuracao = false) {
      if (!this.$refs.form.validate()) return;
      if (!this.periodoPossuiNotas) {
        this.$toast(this.$t('message.recebimento_parcial_sem_nota'));
        return;
      }
      const idTipoUso = this.tipoUso.id;
      const objArmazenamento = {
        ...this.apuracaoParcial,
        idTipoUso,
        dataInicio: formatFirstDayOfTheMonth(moment(this.apuracaoParcial.dataInicio, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
        dataFim: formatlastDayOfTheMonth(moment(this.apuracaoParcial.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
        valorDigitado: this.valorDigitado,
        valorRecebimento: this.isVerbaFixa
          ? this.apuracaoParcial.vlrConfApuracao : this.apuracaoParcial.valorRecebimento,
        valorBase: this.valorBaseDigitado,
        valorBaseOriginal: this.apuracaoParcial.valorBase,
        indImpressaoNdComHistorico: this.indImpressaoNdComHistorico,
        isFinalizarApuracao: indFinalizaApuracao,
      };
      this.resourceApuracaoContratoParcial.gravar(objArmazenamento)
        .then(() => {
          this.apuracao.recebimentoPendente -= this.valorDigitado;
          this.apuracao.valorRecebido += this.valorDigitado;
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.resetarFormulario();
          if (indFinalizaApuracao) {
            this.$emit('ApuracaoFinalizada');
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetarFormulario() {
      this.habilitarFormulario = false;
      this.apuracaoParcial = {
        dataInicio: null,
        dataFim: null,
      };
      this.validarDatasPermitidas();
      this.$refs.formCalcular.reset();
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit('DesabilitaFormRecebimentoParcial');
    },
    limpaDataContabilizacao() {
      this.apuracaoParcial.dataContabilizacao = null;
      this.dataContabilizacaoFormatada = null;
      this.$forceUpdate();
    },
    limpaDataVencimento() {
      this.apuracaoParcial.dataVencimento = null;
      this.dataVencimentoFormatada = null;
    },
    setaDataContabilizacaoFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.apuracaoParcial.dataContabilizacao);
      this.setDataVencimento(this.apuracaoParcial.dataContabilizacao);
    },
    setaDataVencimentoFormatada() {
      this.dataVencimentoFormatada = this.retornaDataFormatada(this.apuracaoParcial.dataVencimento);
    },
    setDataVencimento(data) {
      const dataContabilizacao = data
        ? new Date(moment(data))
        : new Date();
      this.apuracaoParcial.dataVencimento = moment(new Date(
        dataContabilizacao.getFullYear(),
        dataContabilizacao.getMonth(),
        dataContabilizacao.getDate() + this.apuracaoParcial.prazo || 30,
      ), 'DD-MM-YYYY').format('YYYY-MM-DD');

      this.setaDataVencimentoFormatada();
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    formatarVerba(item) {
      if (this.isVerbaFixa) {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    validarForms() {
      this.$refs.formCalcular.validate();
      if (this.$refs.form) {
        this.$refs.form.validate();
      }
    },
    validarDatasPermitidas() {
      const { idApuracao } = this.apuracao,
        mesesApuracao = [],
        mesInicio = moment(this.apuracao.dataInicio, 'DD/MM/YYYY').startOf('month'),
        mesFim = moment(this.apuracao.dataFim, 'DD/MM/YYYY').startOf('month'),
        mesAtual = moment().startOf('month');
      while (mesFim.diff(mesInicio, 'months') >= 0 && mesInicio.isBefore(mesAtual)) {
        mesesApuracao.push(mesInicio.format('YYYY-MM'));
        mesInicio.add(1, 'month');
      }
      this.resourceApuracaoContratoParcial
        .buscarRecebimentosParciais({ idApuracao }, { idApuracao })
        .then((response) => {
          const recebimentosParciais = response.data,
            mesesDosRecebimentos = [];
          recebimentosParciais.forEach((rec) => {
            const mesInicioRecebimentoParcial = moment(rec.dataInicio, 'DD/MM/YYYY').startOf('month');
            const mesFimRecebimentoParcial = moment(rec.dataFim, 'DD/MM/YYYY').startOf('month');
            while (mesFimRecebimentoParcial.diff(mesInicioRecebimentoParcial, 'months') >= 0) {
              mesesDosRecebimentos.push(mesInicioRecebimentoParcial.format('YYYY-MM'));
              mesInicioRecebimentoParcial.add(1, 'month');
            }
          });
          this.mesesValidos = mesesApuracao.filter((mes) => !mesesDosRecebimentos.includes(mes));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    mesesPagamentoParcialValidos(date) {
      return this.mesesValidos.includes(date);
    },
    inicializaDataContabilizacao() {
      this.apuracaoParcial.dataContabilizacao = this.moment().format('YYYY-MM-DD');
      this.setaDataContabilizacaoFormatada();
    },
    clickAppendIcon() {
      if (this.apuracao.formaBonificacao === 'PERCENTUAL') {
        this.valorDigitado = (this.valorBaseDigitado * this.apuracao.verba) / 100;
      }
    },
    showIcon() {
      return this.apuracao.formaBonificacao === 'PERCENTUAL';
    },
  },
  mounted() {
    this.buscarTiposUso();
  },
};
</script>
