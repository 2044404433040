<template>
    <v-card tile style="background: #eee" :key="apuracao.id">
      <v-toolbar dense dark color="primary" elevate-on-scroll>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $tc('title.recebimento_especial', 1) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text style="font-size: 1.1em" class="font-weight-black mt-1">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" class="text-left">
              {{ cabecalhoLinha1 }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" class="text-left">
              {{ cabecalhoLinha2 }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" class="text-left">
              {{ cabecalhoLinha3 }}
            </v-col>
          </v-row>
          <adicionar-recebimento-parcial-resumo
            :apuracao="apuracao"
            :confirmar-alteracao-valor="confirmarAlteracaoValor"
            @AdicionarRecebimento="adicionarRecebimento"
            @ValorRecebimentoAlterado="valorRecebimentoAlterado">
          </adicionar-recebimento-parcial-resumo>
          <adicionar-recebimento-parcial
            ref="adicionarRecebimentoParcial"
            v-show="habilitaFormRecebimentoParcial"
            :apuracao="apuracao"
            @DesabilitaFormRecebimentoParcial="desabilitaFormRecebimentoParcial"
            @ApuracaoFinalizada="definirApuracaoFinalizada">
          </adicionar-recebimento-parcial>
          <recebimento-parcial-tabela
            ref="recebimentoParcialTabela"
            :apuracao="apuracao">
          </recebimento-parcial-tabela>
        </v-container>
      </v-card-text>
    </v-card>
</template>

<script>
import AdicionarRecebimentoParcialResumo from '../recebimento-parcial/AdicionarRecebimentoParcialResumo';
import AdicionarRecebimentoParcial from '../recebimento-parcial/AdicionarRecebimentoParcial';
import RecebimentoParcialTabela from '../recebimento-parcial/RecebimentoParcialTabela';

export default {
  components: {
    AdicionarRecebimentoParcialResumo,
    AdicionarRecebimentoParcial,
    RecebimentoParcialTabela,
  },
  props: {
    apuracao: {},
    confirmarAlteracaoValor: Function,
  },
  data() {
    return {
      habilitaFormRecebimentoParcial: false,
      apuracaoFinalizada: false,
    };
  },
  computed: {
    labels() {
      const map = new Map();
      map.set('descontos', ` - ${this.$tc('label.desconto', 2)}: `);
      map.set('VALOR_LIQUIDO', ` - ${this.$tc('label.valor_net')}`);
      map.set('VALOR_BRUTO', ` - ${this.$tc('label.valor_bruto')}`);
      map.set('SELLIN', ` - ${this.$tc('label.sellin')}`);
      map.set('SELLOUT', ` - ${this.$tc('label.sellout')}`);
      map.set('DEVOLUCOES', `${this.$tc('label.devolucao', 2)}`);
      map.set('ICMS', `${this.$tc('label.icms')}`);
      map.set('IPI', `${this.$tc('label.ipi')}`);
      map.set('PIS_COFINS', `${this.$tc('label.pis_cofins')}`);

      return map;
    },
    cabecalhoLinha1() {
      return ''.concat(this.apuracao.idContrato)
        .concat(' - ')
        .concat(this.apuracao.origemContrato)
        .concat(this.labels.get(this.apuracao.calculoBonificacao))
        .concat(this.labels.get(this.apuracao.baseCalculo))
        .concat(this.labels.get('descontos'))
        .concat(this.formatarDescontos(this.apuracao.descontos, this.labels));
    },
    cabecalhoLinha2() {
      return ''.concat(`${this.$tc('label.acao', 1)}  ${this.apuracao.idAcao}`)
        .concat(` - ${this.$tc('label.apuracao', 1)}  ${this.apuracao.idApuracao}`)
        .concat(' - ')
        .concat(this.apuracao.acaoTipo)
        .concat(' - ')
        .concat(this.apuracao.categoria);
    },
    cabecalhoLinha3() {
      return ''.concat(`${this.$tc('label.periodo_vigencia', 1)}: ${this.apuracao.dataInicio} - ${this.apuracao.dataFim}`);
    },
  },
  methods: {
    close() {
      this.$refs.adicionarRecebimentoParcial.resetarFormulario();
      this.$emit('FecharModalRecebimentoParcial', this.apuracaoFinalizada);
      this.apuracaoFinalizada = false;
    },
    formatarDescontos(descontos, labels) {
      return descontos ? descontos.split(';').map((desconto) => labels.get(desconto)).join('; ') : '';
    },
    adicionarRecebimento() {
      this.habilitaFormRecebimentoParcial = true;
    },
    desabilitaFormRecebimentoParcial() {
      this.habilitaFormRecebimentoParcial = false;
      this.$refs.recebimentoParcialTabela.buscarRecebimentosParciais();
    },
    valorRecebimentoAlterado() {
      this.$refs.adicionarRecebimentoParcial.validarForms();
    },
    definirApuracaoFinalizada() {
      this.apuracaoFinalizada = true;
    },
  },
};
</script>
