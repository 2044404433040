<template>
  <div>
      <v-data-table
        :headers="headerDetalhes"
        :items="fornecedores"
        class="elevation-0 my-3"
        :single-select="true"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        hide-default-footer
        disable-pagination
        :items-per-page="-1">
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox
              :disabled="desabilitaCheckFornecedor(item)"
              v-model="item.selecionado"
              v-if="podeFinalizarFornecedor(item) && item.valorNdPrevisto > 0"
              hide-details
              class="shrink pt-0 mt-0"
              @click.stop="(e) => selecionarItem(item, e)">
            </v-checkbox>
            <v-tooltip bottom v-if="!item.indFornecedorFinalizado && item.valorNdPrevisto <= 0">
              <template v-slot:activator="{ on }">
                <v-simple-checkbox
                    disabled
                    v-on="on"
                    v-model="item.selecionado"
                >
                </v-simple-checkbox>
              </template>
              <span>{{ $t('message.nao_pode_finalizar_fornecedor_nd_previsto_zerado') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.exportar`]="{ item }">
            <tbody>
              <td class="justify-center px-0" style="width: 1%; text-align: center;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="exportarNotas(item)">
                  <v-icon>get_app</v-icon>
                </v-btn>
                  </template>
                  <span>{{ $t('label.exportar') }}</span>
                </v-tooltip>
              </td>
            </tbody>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <v-tooltip bottom v-if="podeFinalizarFornecedor(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="orange darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  report_problem
                </v-icon>
              </template>
              <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="statusAprovado(item) || item.indFornecedorFinalizado">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="deep-orange accent-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>{{ $t('label.periodo_apurado') }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            {{ getMoney(item.total) }}
          </template>

          <template v-slot:[`item.cnpj`]="{ item }">
            {{ item.cnpj }}
          </template>

          <template class="justify-center" v-slot:[`item.devolucoes`]="{ item }">
            {{ formatarValor(item.devolucoes) }}
          </template>

          <template v-slot:[`item.icms`]="{ item }">
            {{ formatarValor(item.icms) }}
          </template>

          <template v-slot:[`item.ipi`]="{ item }">
            {{ formatarValor(item.ipi) }}
          </template>

          <template v-slot:[`item.pisCofins`]="{ item }">
            {{ formatarValor(item.pisCofins) }}
          </template>

          <template v-slot:[`item.valorBase`]="{ item }">
            <div class="mt-2" v-if="!podeEditarValorBase(item)">
              {{ getMoney(item.valorBase) }}
            </div>

            <v-edit-dialog
              :return-value.sync="item"
              v-else
              persistent
              :ref="`${prefixRefNameValorBase}${item.idFornecedor}`"
              @open="isModoEdicaoValoresFornecedor(true, false, item)"
              @close="isModoEdicaoValoresFornecedor(false, false, item)"
            >
              <div class="mt-2">
                {{ getMoney(item.valorBase) }}
                <v-icon class="ml-2">edit</v-icon>
              </div>
              <template v-slot:input>
                <div class="mt-4 text-h6">
                  {{ $t('title.editar')}}
                </div>
                <span class="grey--text caption">
                  {{ `${$tc('label.valor_realizado_original')}: ${formatarValor(item.valorBaseOriginal)}`}}
                </span>
                <div
                  @keydown.esc="cancelarEdicaoValorBase(item)"
                  @keydown.enter="salvarAlteracaoValorBaseFornecedor(item)">
                  <input-money
                    v-model="item.valorBase"
                    :min="0"
                    persistent
                  />
                  <v-text-field
                    :label="$tc('label.justificativa')"
                    v-model="item.justificativaAlteracaoValorBase"
                    type="text"
                    :rules="[rules.required]"
                  >
                  </v-text-field>
                </div>
                <div class="ma-3">
                  <v-btn @click="cancelarEdicaoValorBase(item)" text color="grey"> {{ $t('label.cancelar') }} </v-btn>
                  <v-btn @click="salvarAlteracaoValorBaseFornecedor(item)" text color="primary"> {{ $t('label.salvar') }} </v-btn>
                </div>
              </template>
            </v-edit-dialog>
            <span v-if="formatarValor(item.valorBase) !== formatarValor(item.valorBaseOriginal)" class="grey--text caption">
              {{ getMoney(item.valorBaseOriginal) }}
            </span>
          </template>

          <template v-slot:[`item.valorNdPrevisto`]="{ item }">
            <div class="mt-2" v-if="!podeEditarValorNdPrevisto(item)">
              {{ getMoney(item.valorNdPrevisto) }}
            </div>

            <v-edit-dialog
              :return-value.sync="item"
              v-else
              persistent
              :ref="`${prefixRefNameValorNdPrevisto}${item.idFornecedor}`"
              @open="isModoEdicaoValoresFornecedor(false, true, item)"
              @close="isModoEdicaoValoresFornecedor(false, false, item)"
            >
              <div class="mt-2">
                {{ getMoney(item.valorNdPrevisto) }}
                <v-icon class="ml-2">edit</v-icon>
              </div>
              <template v-slot:input>
                <div class="mt-4 text-h6">
                  {{ $t('title.editar')}}
                </div>
                <span class="grey--text caption">
                  {{ `${$tc('label.valor_realizado_original')}: ${formatarValor(item.valorNdPrevistoOriginal)}`}}
                </span>
                <div
                  @keydown.esc="cancelarEdicaoValoresNdPrevisto(item)"
                  @keydown.enter="salvarAlteracaoValorNdPrevistoFornecedor(item)">
                  <input-money
                  v-model="item.valorNdPrevisto"
                  :min="0"
                  persistent
                  />
                  <v-text-field
                    :label="$tc('label.justificativa')"
                    v-model="item.justificativaAlteracaoValorNdPrevisto"
                    type="text"
                    :rules="[rules.required]"
                  >
                  </v-text-field>
                </div>
                <div class="ma-3">
                  <v-btn @click="cancelarEdicaoValoresNdPrevisto(item)" text color="grey"> {{ $t('label.cancelar') }} </v-btn>
                  <v-btn @click="salvarAlteracaoValorNdPrevistoFornecedor(item)" text color="primary"> {{ $t('label.salvar') }} </v-btn>
                </div>
              </template>
            </v-edit-dialog>
            <span v-if="formatarValor(item.valorNdPrevisto) !== formatarValor(item.valorNdPrevistoOriginal)" class="grey--text caption">
              {{ getMoney(item.valorNdPrevistoOriginal) }}
            </span>
          </template>

          <template v-slot:[`item.valorNdGerado`]="{ item }">
            {{ getMoney(item.valorNdGerado) }}
          </template>

      </v-data-table>
  </div>
</template>

<script>
import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import exportacao from '../../common/functions/exportacao';
import { getMoney, getPercent } from '../../common/functions/helpers';

export default {
  name: 'ApuracaoContratoFinalizarLoteTabelaFornecedor',
  components: { InputMoney },
  props: {
    fornecedores: {
      type: Array,
      default: () => [],
    },
    headerDetalhes: {
      type: Array,
    },
    tipoVerba: {
      type: String,
      required: true,
    },
    valorVerba: {
      type: Number,
      default: 0,
      required: true,
    },
    template: {
      type: String,
      required: true,
    },
    aplicarFiltros: Function,
    selecionarItem: Function,
    indFechamentoParcial: Boolean,
  },
  data() {
    return {
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      novoValorBase: null,
      prefixRefNameValorNdPrevisto: 'modalEditValorNdPrevisto-',
      prefixRefNameValorBase: 'modalEditValorBase-',
      isVlrBaseFornecedorEmEdicao: false,
      isVlrNdPrevistoFornecedorEmEdicao: false,
      modalEdicaoValor: null,
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
      },
      justificativaAlteracaoValorBaseInicial: '',
      justificativaAlteracaoValorNdPrevistoInicial: '',
    };
  },
  computed: {
    somatorioValorBaseOriginalFornecedores() {
      return this.fornecedores.filter((fornecedor) => !fornecedor.indFornecedorFinalizado).map((fornecedor) => fornecedor.valorBaseOriginal)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    somatorioValorNdPrevistoOriginalFornecedores() {
      return this.fornecedores.filter((fornecedor) => !fornecedor.indFornecedorFinalizado)
        .map((fornecedor) => fornecedor.valorNdPrevistoOriginal)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
  },
  methods: {
    getPercent,
    getMoney,
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    desabilitaCheckFornecedor(item) {
      if (item.valorNdGerado !== 0 || this.modalEdicaoValor || item.valorNdPrevisto === 0) {
        return true;
      }
      return false;
    },
    exportarNotas(item) {
      if (!item.possuiNotas) {
        this.$toast(this.$t('message.sem_dados_notas_fiscais_apuracao'));
      }
      const params = {
        id_acao: Number(item.idAcao),
        id_apuracao: Number(item.idApuracao),
        id_fornecedor: item.idFornecedor,
        status: item.statusApuracao,
      };

      if (item.calculoBonificacao === 'SELLOUT') {
        exportacao.exportar(null, 'notas_sellout_memoria_apuracao_contrato_antigo', this, params);
      } else {
        exportacao.exportar(null, 'notas_sellin_memoria_apuracao_contrato_antigo', this, params);
      }
    },
    guardarValoresIniciais(item) {
      if (item.justificativaAlteracaoValorBase && !!item.justificativaAlteracaoValorBase) {
        this.justificativaAlteracaoValorBaseInicial = item.justificativaAlteracaoValorBase;
      }
      if (item.justificativaAlteracaoValorNdPrevisto && !!item.justificativaAlteracaoValorNdPrevisto) {
        this.justificativaAlteracaoValorNdPrevistoInicial = item.justificativaAlteracaoValorNdPrevisto;
      }
      item.valorBaseInicial = item.valorBase;
      item.valorNdPrevistoInicial = item.valorNdPrevisto;
    },
    isModoEdicaoValoresFornecedor(edicaoValorBase, edicaoValorNdPrevisto, item = null) {
      this.isVlrBaseFornecedorEmEdicao = edicaoValorBase;
      this.isVlrNdPrevistoFornecedorEmEdicao = edicaoValorNdPrevisto;
      if (item) {
        this.guardarValoresIniciais(item);
      }
      const isModoEdicao = (edicaoValorBase || edicaoValorNdPrevisto);
      this.modalEdicaoValor = isModoEdicao;
      this.$emit('ApuracaoContratoFinalizarLoteTabela__ModoEdicaoValoresFornecedor', isModoEdicao);
    },
    isEdicaoValoresValida(novoValor, valorOriginal, justificativa) {
      if (novoValor < 0
         || ((novoValor !== valorOriginal) && (!justificativa || !justificativa.trim().length))) {
        return false;
      }
      return true;
    },
    obterRefModalEdicaoValor(prefixRef, idFornecedor) {
      const modalRefName = prefixRef.concat(idFornecedor);
      return this.$refs[modalRefName];
    },
    cancelarEdicaoValorBase(item) {
      const modalRef = this.obterRefModalEdicaoValor(this.prefixRefNameValorBase, item.idFornecedor);
      item.valorBase = item.valorBaseInicial;
      this.resetarJustificativaAlteracaoValorBase(item);
      this.modalEdicaoValor = false;
      modalRef.isActive = false;
    },
    resetarJustificativaAlteracaoValorBase(item) {
      if (this.justificativaAlteracaoValorBaseInicial !== '') {
        item.justificativaAlteracaoValorBase = this.justificativaAlteracaoValorBaseInicial;
      } else {
        delete item.justificativaAlteracaoValorBase;
      }
    },
    cancelarEdicaoValoresNdPrevisto(item) {
      const modalRef = this.obterRefModalEdicaoValor(this.prefixRefNameValorNdPrevisto, item.idFornecedor);
      item.valorNdPrevisto = item.valorNdPrevistoInicial;
      this.resetarJustificativaAlteracaoValorNdPrevisto(item);
      this.modalEdicaoValor = false;
      modalRef.isActive = false;
    },
    resetarJustificativaAlteracaoValorNdPrevisto(item) {
      if (this.justificativaAlteracaoValorNdPrevistoInicial !== '') {
        item.justificativaAlteracaoValorNdPrevisto = this.justificativaAlteracaoValorNdPrevistoInicial;
      } else {
        delete item.justificativaAlteracaoValorNdPrevisto;
      }
    },
    salvarAlteracaoValorBaseFornecedor(fornecedor) {
      const isEdicaoValida = this.isEdicaoValoresValida(fornecedor.valorBase, fornecedor.valorBaseOriginal,
        fornecedor.justificativaAlteracaoValorBase);

      const modalRef = this.obterRefModalEdicaoValor(this.prefixRefNameValorBase, fornecedor.idFornecedor);
      modalRef.isActive = !isEdicaoValida;

      /* Caso os valores forem iguais não deverá ser recalculado novamente. */
      if (!isEdicaoValida || (fornecedor.valorBaseInicial === fornecedor.valorBase)) {
        this.resetarJustificativaAlteracaoValorBase(fornecedor);
        return;
      }
      const fornecedorResumo = this.fornecedorResumo(fornecedor, fornecedor.justificativaAlteracaoValorBase, true);
      fornecedorResumo.indValorNdPrevistoAlterado = false;
      this.atualizarFornecedorResumo(fornecedor.idAcao, fornecedorResumo);
    },
    salvarAlteracaoValorNdPrevistoFornecedor(fornecedor) {
      const isEdicaoValida = this.isEdicaoValoresValida(fornecedor.valorNdPrevisto, fornecedor.valorNdPrevistoOriginal,
        fornecedor.justificativaAlteracaoValorNdPrevisto);

      const modalRef = this.obterRefModalEdicaoValor(this.prefixRefNameValorNdPrevisto, fornecedor.idFornecedor);
      modalRef.isActive = !isEdicaoValida;

      /* Caso os valores forem iguais não deverá ser recalculado novamente. */
      if (fornecedor.valorNdPrevistoInicial === fornecedor.valorNdPrevisto) {
        this.resetarJustificativaAlteracaoValorNdPrevisto(fornecedor);
        return;
      }
      const fornecedorResumo = this.fornecedorResumo(fornecedor, fornecedor.justificativaAlteracaoValorNdPrevisto, false);
      fornecedorResumo.indValorNdPrevistoAlterado = true;
      this.atualizarFornecedorResumo(fornecedor.idAcao, fornecedorResumo);
    },
    async atualizarFornecedorResumo(idAcao, fornecedorResumo) {
      await this.apuracaoAcaoResource.atualizarFornecedorResumo({ idAcao }, fornecedorResumo);
      this.$emit('ApuracaoContratoFinalizarLoteTabela__RecarregarApuracoes', fornecedorResumo.idApuracao.toString());
    },
    fornecedorResumo(fornecedor, justificativa) {
      return {
        idApuracao: fornecedor.idApuracao,
        idFornecedor: fornecedor.idFornecedor,
        valorBase: fornecedor.valorBase,
        valorBaseOriginal: fornecedor.valorBaseOriginal,
        coeficiente: fornecedor.coeficiente,
        valorNdPrevisto: fornecedor.valorNdPrevisto,
        valorNdPrevistoOriginal: fornecedor.valorNdPrevistoOriginal,
        valorNdGerado: fornecedor.valorNdGerado,
        verba: this.valorVerba,
        template: this.template,
        justificativaAlteracao: justificativa,
      };
    },
    podeEditarValorBase(item) {
      return !this.statusAprovado(item) && !this.isVlrNdPrevistoFornecedorEmEdicao
             && !item.indFornecedorFinalizado && !item.possuiRecebimentoParcial;
    },
    podeEditarValorNdPrevisto(item) {
      return !this.statusAprovado(item) && !this.isVlrBaseFornecedorEmEdicao
             && !item.indFornecedorFinalizado && !item.possuiRecebimentoParcial;
    },
    podeFinalizarFornecedor(item) {
      return !this.statusAprovado(item) && !item.indFornecedorFinalizado && !item.possuiRecebimentoParcial;
    },
  },
};
</script>
